import React, { useContext, useState } from "react";
import { Alert, Button, Card, Form, Modal } from "react-bootstrap";

import * as FiIcons from "react-icons/fi";
import { useParams } from "react-router-dom";
import { UtilitiesContext } from "../context/UtilitiesContext";
import { consultationInvoice } from "../service/consultationService";

export const UnderProcessModal = ({ isOpen, close, data, getData }) => {
  const { setModal, setLoadingScreen } = useContext(UtilitiesContext);
  const { id: consultationId } = useParams();

  const [formData, setFormData] = useState({
    total: "",
    description: "",
    invoice: null,
    calculation: null,
    extension: false,
    extensionPrice: "",
  });

  function generateInvoice() {
    const formDataFiles = new FormData();
    formDataFiles.append("service_price", formData.total);
    formDataFiles.append("service_description", formData.description);
    formDataFiles.append("calculation", formData.calculation);
    if (formData.extension) {
      formDataFiles.append("extension_price", formData.extensionPrice);
    }

    setModal({
      display: true,
      message: "Are you sure you want to generate the invoice?",
      action: () => {
        setLoadingScreen((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
        consultationInvoice(consultationId, formDataFiles)
          .then((res) => {
            setLoadingScreen({
              isLoading: false,
              message: "",
            });
            setModal({
              display: false,
              message: "",
              action: null,
            });
            getData();
            close();
          })
          .catch((e) => {
            console.log(e);
            setLoadingScreen({
              isLoading: false,
              message: "",
            });
            setModal({
              display: false,
              message: "",
              action: null,
            });
          });
      },
    });
  }

  const isValidated = !formData.extension
    ? !!formData.total && !!formData.description && !!formData.calculation
    : !!formData.total &&
      !!formData.description &&
      !!formData.calculation &&
      !!formData.extensionPrice;

  const formattedDuration = () => {
    return (
      <>
        <span>
          {("0" + Math.floor((data.duration / 6000000) % 60)).slice(-2)}:
        </span>
        <span>
          {("0" + Math.floor((data.duration / 60000) % 60)).slice(-2)}:
        </span>
        <span>
          {("0" + Math.floor((data.duration / 1000) % 60)).slice(-2)}:
        </span>
        <span>{("0" + ((data.duration / 10) % 100)).slice(-2)}</span>
      </>
    );
  };

  return (
    <Modal show={isOpen} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Prepare Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
          <Card.Body className="pb-0">
            <Card.Title className="text-center m-0">
              Total consultation call duration
            </Card.Title>
          </Card.Body>
          <Card.Body>
            <Alert
              className="d-flex align-items-center justify-content-center m-0"
              variant="success"
            >
              <h1 className="m-0" style={{ fontSize: "2em" }}>
                {formattedDuration()}
              </h1>
            </Alert>
          </Card.Body>
        </Card>
        <Form className="mt-3">
          <Form.Label className="">
            Upload Calculations<span className="text-danger">*</span> (.pdf
            files only)
          </Form.Label>
          {formData.calculation ? (
            <Alert className="p-2">{formData.calculation.name}</Alert>
          ) : null}
          <div className="bg-light rounded p-0 border border-primary position-relative">
            <Form.Group
              controlId="formFile"
              className="m-3 rounded"
              style={{ borderStyle: "dashed", borderColor: "inherit" }}
            >
              <Form.Control
                onChange={(e) =>
                  setFormData((prevState) => ({
                    ...prevState,
                    calculation: e.target.files[0],
                  }))
                }
                type="file"
                style={{ height: "100px", opacity: 0 }}
                accept=".pdf"
              />
            </Form.Group>
            <div className="position-absolute top-0 left-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center pe-none">
              <FiIcons.FiUpload className="me-2" fontSize={"1.5em"} />
              <p className="m-0">Click or drag and drop your files here</p>
            </div>
          </div>
          <Form.Label className="mt-3">
            Main Service Price<span className="text-danger">*</span>
          </Form.Label>
          <div className="d-flex align-items-center gap-2">
            $
            <Form.Control
              type="number"
              placeholder="1000"
              min={0}
              value={formData.total}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  total: e.target.value,
                }))
              }
            />
          </div>
          <Form.Label className="mt-3">
            Main Service Description<span className="text-danger">*</span>
          </Form.Label>
          <Form.Control
            as="textarea"
            rows={2}
            value={formData.description}
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                description: e.target.value,
              }))
            }
          />
          <Form.Check
            className="mt-3"
            type="checkbox"
            label="Did you file for an extension?"
            value={formData.extension}
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                extension: !formData.extension,
              }))
            }
          />
          {formData.extension ? (
            <>
              <Form.Label className="mt-3">
                File extension price<span className="text-danger">*</span>
              </Form.Label>
              <div className="d-flex align-items-center gap-2">
                $
                <Form.Control
                  type="number"
                  placeholder="1000"
                  min={0}
                  value={formData.extensionPrice}
                  onChange={(e) =>
                    setFormData((prevState) => ({
                      ...prevState,
                      extensionPrice: e.target.value,
                    }))
                  }
                />
              </div>
            </>
          ) : null}
          <Button
            disabled={!isValidated}
            onClick={generateInvoice}
            className="rounded mt-3 w-100"
          >
            Generate Invoice
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
