import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row, Table } from "react-bootstrap";
import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Link } from "react-router-dom";
import { Header } from "../../components";
import { UtilitiesContext } from "../../context/UtilitiesContext";
import { getUsers } from "../../service/userService";

import tableStyles from "../../styles/Table.module.css";

function UserList() {
  const { setLoadingScreen } = useContext(UtilitiesContext);

  const [data, setData] = useState([]);

  const headers = [
    {
      title: "Name",
      prop: "firstname",
      isSortable: true,
      isFilterable: true,

      cell: (item) => {
        return `${item.firstname} ${item.lastname}`;
      },
    },
    {
      title: "Address",
      prop: "address",
      isSortable: true,
      isFilterable: true,
      cell: (item) => {
        return `${item.address}${item.address ? ", " : ""}${item.city}${
          item.city ? ", " : ""
        }${item.state}${item.state ? ", " : ""}${item.zip}${
          item.zip ? ", " : ""
        }`;
      },
    },
    {
      title: "",
      prop: "view",
      cell: (item) => {
        return (
          <Link to={`${item.id}`}>
            <Button className="rounded">View</Button>
          </Link>
        );
      },
    },
  ];

  useEffect(() => {
    setLoadingScreen({
      isLoading: true,
    });
    getUsers()
      .then((res) => {
        setData(res.data.data.filter((user) => user.type === "client"));
        setLoadingScreen({
          isLoading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        setLoadingScreen({
          isLoading: false,
        });
      });
  }, []);

  return (
    <Container>
      <Header title={"Users"} />
      <DatatableWrapper
        body={data}
        headers={headers}
        paginationOptionsProps={{
          initialState: {
            rowsPerPage: 10,
            options: [5, 10, 15, 20],
          },
        }}
        initialSort={{
          prop: "accepted",
          isAscending: true,
        }}
      >
        <Row className="mb-4">
          <Col
            xs={12}
            lg={4}
            className="d-flex flex-col justify-content-end align-items-end"
          >
            <Filter />
          </Col>
          <Col
            xs={12}
            sm={6}
            lg={4}
            className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
          >
            <PaginationOptions />
          </Col>
          <Col
            xs={12}
            sm={6}
            lg={4}
            className="d-flex flex-col justify-content-end align-items-end"
          >
            <Pagination />
          </Col>
        </Row>
        <Table className={`${tableStyles["table"]}`}>
          <TableHeader />
          <TableBody />
        </Table>
      </DatatableWrapper>
    </Container>
  );
}

export default UserList;
