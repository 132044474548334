import { useContext, useEffect, useState } from "react";
import { Alert, Button, Form, Modal, Table } from "react-bootstrap";
import { DatatableWrapper, TableBody, TableHeader } from "react-bs-datatable";

import * as BiIcons from "react-icons/bi";
import { useNavigate, useParams } from "react-router-dom";
import { UtilitiesContext } from "../context/UtilitiesContext";
import {
  getConsultations,
  updateConsultation,
} from "../service/consultationService";

import tableStyles from "../styles/Table.module.css";

export const RejectionModal = ({
  isOpen,
  close,
  handleReject,
  readOnly,
  status,
}) => {
  const { id: consultationId } = useParams();
  const navigate = useNavigate();

  const { setModal, setLoadingScreen } = useContext(UtilitiesContext);

  const [userType] = useState(localStorage.getItem("user_type"));
  const [display, setDisplay] = useState("message");
  const [id, setId] = useState();
  const [data, setData] = useState([]);
  const [newRejection, setNewRejection] = useState("");

  function getData() {
    setLoadingScreen((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    getConsultations()
      .then((res) => {
        const findConsultation = res.data.data.find(
          (item) => item.id.toString() === consultationId
        );
        setData(
          findConsultation.rejection_reasons.map((rejection, index) => ({
            ...rejection,
            count: index + 1,
          }))
        );
        setLoadingScreen({
          isLoading: false,
          message: "",
        });
      })
      .catch((e) => {
        console.log(e);
        setLoadingScreen({
          isLoading: false,
          message: "",
        });
      });
  }

  function handleCreateRejection() {
    if (newRejection) {
      setModal({
        display: true,
        message:
          "Are you sure you want to send this additional information message?",
        action: () => {
          setLoadingScreen((prevState) => ({
            ...prevState,
            isLoading: true,
          }));
          updateConsultation(consultationId, {
            status: "rejected",
            rejection_reason: newRejection,
          })
            .then((res) => {
              setLoadingScreen({
                isLoading: false,
                message: "",
              });
              setModal({
                display: false,
                message: "",
                action: null,
              });
              setNewRejection("");
              // handleReject();
              getData();
              close();
              navigate(-1);
            })
            .catch((e) => {
              console.log(e);
              setLoadingScreen({
                isLoading: false,
                message: "",
              });
              setModal({
                display: false,
                message: "",
                action: null,
              });
            });
        },
      });
    } else {
      setModal({
        display: true,
        message:
          "Please make sure that you have entered the reason for rejection",
        action: () => {
          setData({
            display: false,
            message: "",
            action: null,
          });
        },
      });
    }

    // handleReject()
  }

  useEffect(() => {
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data.length && (userType === "client" || readOnly)) {
      setId(data[data.length - 1].count);
    } else {
      setId(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const historyHeaders = [
    {
      title: "No.",
      prop: "count",
      isSortable: true,
      alignment: {
        horizontal: "center",
      },
    },
    {
      title: "Rejected At",
      prop: "created_at",
      isSortable: true,
      alignment: {
        horizontal: "center",
      },
      cell: (item) => new Date(item.created_at).toLocaleDateString(),
    },
    {
      title: "",
      prop: "",
      cell: (item) => {
        return (
          <Button
            className=" rounded"
            onClick={() => {
              setId(item.count);
              setDisplay("message");
            }}
          >
            View
          </Button>
        );
      },
    },
  ];

  const displayContent = () => {
    if (display === "message") {
      return (
        <Form.Group
          className="mb-3 d-flex flex-column h-100"
          controlId="exampleForm.ControlTextarea1"
        >
          <Form.Control
            as="textarea"
            rows={10}
            placeholder="Please provide additional information needed"
            className="flex-grow-1"
            value={
              data && id
                ? data.find((item) => item.count === id)?.reason
                : newRejection
            }
            readOnly={userType === "client" || readOnly}
            onChange={(e) => setNewRejection(e.target.value)}
          />
          {userType === "admin" && !readOnly ? (
            status !== "underProcess" ? (
              <Button
                className="rounded mt-3"
                variant="danger"
                onClick={handleCreateRejection}
              >
                Send
              </Button>
            ) : (
              <Alert className=" mt-3">
                Client's uploaded documents has already been accepted.
              </Alert>
            )
          ) : null}
        </Form.Group>
      );
    } else if (display === "history") {
      return (
        <DatatableWrapper body={data} headers={historyHeaders}>
          <Table className={`${tableStyles["table"]}`}>
            <TableHeader />
            <TableBody />
          </Table>
        </DatatableWrapper>
      );
    }
  };

  return (
    <Modal show={isOpen} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Additional Information</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="danger">Additional Information Message</h5>
          <div>
            {display === "message" ? (
              <div className="d-flex gap-2">
                {userType === "admin" && !readOnly ? (
                  <Button
                    className="rounded"
                    onClick={() => {
                      setId(null);
                      setNewRejection("");
                    }}
                  >
                    <BiIcons.BiReset />
                  </Button>
                ) : null}
                <Button
                  onClick={() => setDisplay("history")}
                  className="rounded"
                >
                  History
                </Button>
              </div>
            ) : (
              <Button onClick={() => setDisplay("message")} className="rounded">
                Go Back
              </Button>
            )}
          </div>
        </div>
        <hr />
        {displayContent()}
      </Modal.Body>
    </Modal>
  );
};
