import React from "react";
import { Container, Alert } from "react-bootstrap";

import { Button, LoadingScreen, ConsultationForm } from "../../components";

import { useRequestConsultation } from "./useRequestConsulation";

function RequestConsultation({ free }) {
  const {
    handleValidation,
    requestConsultationFormData,
    handleRequestConsultationFormData,
    formSubmit,
    displayMsg,
    showLoading,
    setShowLoading,
    loadingMsg,
  } = useRequestConsultation();

  return (
    <Container className="mt-50">
      <h1 className="primary">Request {free ? "Free" : ""} Consultation</h1>
      <hr />

      {displayMsg ? (
        <Alert variant={displayMsg.type === "error" ? "danger" : "success"}>
          {displayMsg.message}
        </Alert>
      ) : null}
      <ConsultationForm
        formData={requestConsultationFormData}
        setFormData={handleRequestConsultationFormData}
        formSubmit={formSubmit}
      />

      <div className="mt-50 mb-3">
        <center>
          <Button
            buttonStyle="btn--primary btn--large"
            onClick={() => handleValidation()}
          >
            Submit Request
          </Button>
        </center>
      </div>

      <LoadingScreen
        show={showLoading}
        onHide={() => setShowLoading(false)}
        message={loadingMsg}
        redirect="false"
      />
    </Container>
  );
}

export default RequestConsultation;
