import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { AppConstants } from "../AppConstants";
import { config } from "./useAxios";

function useSession() {
  const dbBaseUrl = AppConstants().db_base_url;
  const baseUrl = AppConstants().base_url;

  const [isAuth, setIsAuth] = useState(false);
  const [userType, setUserType] = useState(null);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
  });

  function checkSession() {
    setIsAuth(!!localStorage.getItem("token"));
  }

  async function getUserInfo() {
    await axios
      .get(
        `${process.env.REACT_APP_DB_BASE_URL}me/info`,
        config(localStorage.getItem("token"))
      )
      .then((res) => {
        const user = res.data.data;

        setUser({
          firstName: user.firstname,
          lastName: user.lastname,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    if (localStorage.getItem("token")) {
      getUserInfo();
    }
    setIsAuth(!!localStorage.getItem("token"));
    setUserType(localStorage.getItem("user_type"));
  }, []);

  return {
    url: { dbBaseUrl, baseUrl },
    user,
    checkSession,
    isAuth,
    getUserInfo,
    userType,
  };
}

export default useSession;
