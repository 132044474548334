import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

function Redirect() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [userType] = useState(localStorage.getItem("user_type"));

  const routesToRedirect = ["/login", "/admin/login", "/register"];

  useEffect(() => {
    if (routesToRedirect.some((route) => route === pathname)) {
      if (userType === "client") {
        navigate("/consultation");
      } else if (userType === "admin") {
        navigate("/dashboard");
      }
    } else {
      window.location.reload();
    }

    // navigate("/dashboard");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
}

export default Redirect;
