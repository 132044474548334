import React, { useEffect, useState } from "react";

import "./App.css";

import useSession from "./hooks/useSession";

import { LoadingScreen, Navbar, Sidebar } from "./components";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import LandingPage from "./pages/LandingPage";
import Registration from "./pages/auth/Registration";
import Login from "./pages/auth/Login";
import ForgotPassword from "./pages/ForgotPassword";

import ClientDashboard from "./pages/ClientDashboard";
import AdminDashboard from "./pages/AdminDashboard";

import Consultations from "./pages/Consultations";
import Consultation from "./pages/Consultation";
import ConsultationFiles from "./pages/ConsultationFiles";
import RequestConsultation from "./pages/RequestConsultation";

import Checkout from "./pages/Checkout";

import QuickQuote from "./pages/QuickQuote";

import Settings from "./pages/Settings";

import Redirect from "./pages/Redirect";

import UserList from "./pages/UserList";

import { UtilitiesContext } from "./context/UtilitiesContext";
import { Button, Modal } from "react-bootstrap";
import UserInfo from "./pages/UserInfo";

function App() {
  const [loadingScreen, setLoadingScreen] = useState({
    isLoading: false,
    message: "",
  });
  const [modal, setModal] = useState({
    display: false,
    message: "",
    action: null,
    actionLabel: "",
  });
  const [header, setHeader] = useState("");

  const { url, isAuth } = useSession();

  const currentPath = window.location.href;

  useEffect(() => {
    //eslint-disable-next-line
  }, []);

  return (
    <>
      <LoadingScreen
        show={loadingScreen.isLoading}
        message={loadingScreen.message}
        redirect="false"
      />
      <Modal
        show={modal.display}
        onHide={() =>
          setModal((prevState) => ({ ...prevState, display: false }))
        }
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>{modal.message}</Modal.Body>
        <Modal.Footer>
          <Button
            className="rounded"
            variant="secondary"
            onClick={() =>
              setModal((prevState) => ({ ...prevState, display: false }))
            }
          >
            Close
          </Button>
          <Button className="rounded" variant="primary" onClick={modal.action}>
            {modal.actionLabel ? modal.actionLabel : "OK"}
          </Button>
        </Modal.Footer>
      </Modal>
      <UtilitiesContext.Provider
        value={{
          loadingScreen,
          setLoadingScreen,
          modal,
          setModal,
          header,
          setHeader,
        }}
      >
        <Router>
          <div className="d-flex flex-column vh-100">
            <Navbar />
            {!isAuth || (isAuth && url.baseUrl === currentPath) ? (
              <>
                <Routes>
                  <Route exact path="/" element={<LandingPage />} />
                  <Route exact path="*" element={<LandingPage />} />
                  <Route path="/register" element={<Registration />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/admin/login" element={<Login />} />
                  <Route path="/auth/:content" element={<ForgotPassword />} />
                </Routes>
              </>
            ) : null}

            {isAuth && url.baseUrl !== currentPath ? (
              <Sidebar>
                {localStorage.getItem("user_type") === "client" ? (
                  <Routes>
                    <Route
                      exact
                      path="/dashboard"
                      element={<ClientDashboard />}
                    />
                    <Route path="/consultation">
                      <Route index element={<Consultations />} />
                      <Route path="request">
                        <Route index element={<RequestConsultation />} />
                        <Route
                          path="free"
                          element={<RequestConsultation free />}
                        />
                      </Route>
                      <Route path=":id">
                        <Route index element={<Consultation />} />
                        <Route path="files" element={<ConsultationFiles />} />
                      </Route>
                    </Route>
                    ?submission_id
                    <Route
                      path="checkout/:status"
                      element={<Checkout />}
                    ></Route>
                    <Route exact path="/quick-quote" element={<QuickQuote />} />
                    <Route exact path="/settings" element={<Settings />} />
                    <Route exact path="*" element={<Redirect />} />
                  </Routes>
                ) : (
                  <Routes>
                    <Route
                      exact
                      path="/admin/dashboard"
                      element={<AdminDashboard />}
                    />
                    <Route exact path="/admin/users" element={<UserList />} />
                    <Route path="/admin/users">
                      <Route index element={<UserList />} />
                      <Route path=":id" element={<UserInfo />} />
                    </Route>
                    <Route path="/admin/consultation">
                      <Route index element={<Consultations />} />
                      <Route path=":id">
                        <Route index element={<Consultation />} />
                        <Route path="files" element={<ConsultationFiles />} />
                      </Route>
                    </Route>
                    <Route exact path="/settings" element={<Settings />} />
                    <Route exact path="*" element={<Redirect />} />
                  </Routes>
                )}
              </Sidebar>
            ) : null}
          </div>
        </Router>
      </UtilitiesContext.Provider>
    </>
  );
}

export default App;
