import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Alert } from "react-bootstrap";

export const QuickQuoteBreakdown = ({ serviceType, formData }) => {
  const [total, setTotal] = useState(0);

  useEffect(() => {
    setTotal(
      Object.keys(formData).reduce((total, item) => {
        total += formData[item].value;
        return total;
      }, 0) + serviceType?.value
    );
  }, [serviceType, formData]);
  return (
    <Container>
      <Row>
        <Col className="mt-4 mt-md-0" xs={12} md={6} lg={9}>
          <Row>
            <Col>
              <h6>Service Type</h6>
              <h3>{serviceType?.name}</h3>
            </Col>
            <Col className="d-flex justify-content-end">
              <h3 className="align-self-center m-0">
                {serviceType?.value || ""}
              </h3>
            </Col>
          </Row>
          <hr />
          {formData
            ? Object.keys(formData).map((item, index) => {
                return (
                  <>
                    <Row key={index}>
                      <Col>
                        <h6>{formData[item].question}</h6>
                        <h3>{formData[item].name}</h3>
                      </Col>
                      <Col className="d-flex justify-content-end">
                        <h3 className="align-self-center m-0">
                          {formData[item].value || ""}
                        </h3>
                      </Col>
                    </Row>
                    <hr />
                  </>
                );
              })
            : null}
        </Col>
        <Col className="order-first order-md-last" xs={12} md={6} lg={3}>
          <Card className="ms-0 ms-md-3">
            <Card.Body>
              <center><p>Starting at</p></center>
              <Alert
                className="d-flex align-items-center justify-content-center m-0"
                variant="success"
              >
                <h1 style={{ fontSize: "2em" }}>${total} USD</h1>
              </Alert>
            </Card.Body>
            <Card.Body>
              <Card.Title className="text-center">Total</Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
