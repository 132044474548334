import React, { useState } from "react";
import { Container, Row, Col, Modal, Alert } from "react-bootstrap";
import Select from "react-select";

import { AppConstants } from "../../AppConstants";

import { Button, LoadingScreen } from "../../components";

import { register } from "../../service/authService";

import useSession from "../../hooks/useSession";

function Registration() {
  const { url } = useSession();

  const [formSubmit, setFormSubmit] = useState(false);

  const [displayMsg, setDisplayMsg] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [telNumber, setTelNumber] = useState("");
  const [email, setEmail] = useState("");

  const [address, setAddress] = useState("");
  const [county, setCounty] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("Florida");
  const [zip, setZip] = useState("");

  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [termsChecked, setTermsChecked] = useState(false);
  const [taxUpdates, setTaxUpdates] = useState(false);
  const [promoUpdates, setPromoUpdates] = useState(false);

  function handleValidation() {
    setFormSubmit(true);
    setShowLoading(true);
    setLoadingMsg("Validating information. Please wait.");

    if (firstname === "" || firstname === null) {
      window.scrollTo(0, 0);
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "First name and middle initial cannot be empty",
      });
    }
    if (lastname === "" || lastname === null) {
      window.scrollTo(0, 0);
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "Last name cannot be empty",
      });
    }
    if (phoneNumber === "" || phoneNumber === null) {
      window.scrollTo(0, 0);
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "Phone number cannot be empty",
      });
    } else {
      if (phoneNumber.length > 10 || phoneNumber.length < 10) {
        window.scrollTo(0, 0);
        setShowLoading(false);
        return setDisplayMsg({
          type: "error",
          message: "Phone numbers should not be lower or exceed 10 digits",
        });
      }
    }
    if (telNumber !== "" && (telNumber.length > 10 || telNumber.length < 10)) {
      window.scrollTo(0, 0);
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "Telephone numbers should not be lower or exceed 10 digits",
      });
    }
    if (email === "" || email === null) {
      window.scrollTo(0, 0);
      setShowLoading(false);
      return setDisplayMsg({ type: "error", message: "Email cannot be empty" });
    }
    if (address === "" || address === null) {
      window.scrollTo(0, 0);
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "Address cannot be empty",
      });
    }
    if (county === "" || county === null) {
      window.scrollTo(0, 0);
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "Address cannot be empty",
      });
    }
    if (city === "" || city === null) {
      window.scrollTo(0, 0);
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "Address cannot be empty",
      });
    }
    if (state === "" || state === null) {
      window.scrollTo(0, 0);
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "Address cannot be empty",
      });
    }
    if (zip === "" || zip === null) {
      window.scrollTo(0, 0);
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "Address cannot be empty",
      });
    }

    if (password === "" || password === null) {
      window.scrollTo(0, 0);
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "Password cannot be empty",
      });
    }

    if (!termsChecked) {
      window.scrollTo(0, 0);
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message:
          "Please agree to our terms and condition to complete your registration.",
      });
    }

    handleSubmit();
  }

  function handleSubmit() {
    register({
      firstname: firstname,
      lastname: lastname,
      phone_number: phoneNumber,
      tel_number: telNumber,
      email: email,
      address: address,
      county: county,
      city: city,
      state: state,
      zip: zip,
      password: password,
      terms_and_conditions: termsChecked,
      tax_updates: taxUpdates,
      promo_updates: promoUpdates,
    })
      .then((res) => {
        setShowLoading(false);
        window.location.replace(url.baseUrl + "login");
      })
      .catch((e) => {
        setDisplayMsg({
          type: "error",
          message: "Error",
        });
      });
  }

  //modal
  const [showTermsModal, setShowTermsModal] = useState(false);

  return (
    <Container className="container-space">
      <h1 className="primary">Registration</h1>
      <hr />
      {displayMsg !== null ? (
        <>
          <Alert variant={displayMsg.type === "error" ? "danger" : "success"}>
            {displayMsg.message}
          </Alert>
          {displayMsg.type === "success" ? (
            <center>
              <Button
                buttonStyle="btn--primary"
                onClick={() => window.location.replace(url.baseUrl + "login")}
              >
                Login
              </Button>
            </center>
          ) : null}
        </>
      ) : null}
      <Row>
        <Col sm={12} lg={6}>
          <input
            type="text"
            className={`mt-20 ${
              formSubmit && (firstname === "" || firstname === null)
                ? "is-invalid"
                : ""
            }`}
            onChange={(e) => setFirstname(e.target.value)}
          />
          <label>
            First name and Middle Initial<span className="danger">*</span>
          </label>
        </Col>
        <Col sm={12} lg={6}>
          <input
            type="text"
            className={`mt-20 ${
              formSubmit && (lastname === "" || lastname === null)
                ? "is-invalid"
                : ""
            }`}
            onChange={(e) => setLastname(e.target.value)}
          />
          <label>
            Last name<span className="danger">*</span>
          </label>
        </Col>

        <Col sm={12} lg={6}>
          <input
            type="number"
            className={`mt-20 ${
              formSubmit && (phoneNumber === "" || phoneNumber === null)
                ? "is-invalid"
                : ""
            }`}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <label>
            Phone Number<span className="danger">*</span>
          </label>
        </Col>
        <Col sm={12} lg={6}>
          <input
            type="number"
            className={`mt-20 ${
              formSubmit && (telNumber === "" || telNumber === null)
                ? "is-invalid"
                : ""
            }`}
            onChange={(e) => setTelNumber(e.target.value)}
          />
          <label>Telephone Number</label>
        </Col>

        <Col sm={12} lg={6}>
          <input
            type="text"
            className={`mt-20 ${
              formSubmit && (email === "" || email === null) ? "is-invalid" : ""
            }`}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label>
            Email<span className="danger">*</span>
          </label>
        </Col>
      </Row>
      <hr />

      <Row>
        <Col lg={12}>
          <input
            type="text"
            className={`mt-20 ${
              formSubmit && (address === "" || address === null)
                ? "is-invalid"
                : ""
            }`}
            onChange={(e) => setAddress(e.target.value)}
          />
          <label>
            Address<span className="danger">*</span>
          </label>
        </Col>

        <Col sm={12} lg={6}>
          <input
            type="text"
            className={`mt-20 ${
              formSubmit && (county === "" || county === null)
                ? "is-invalid"
                : ""
            }`}
            onChange={(e) => setCounty(e.target.value)}
          />
          <label>
            County<span className="danger">*</span>
          </label>
        </Col>
        <Col sm={12} lg={6}>
          <input
            type="text"
            className={`mt-20 ${
              formSubmit && (city === "" || city === null) ? "is-invalid" : ""
            }`}
            onChange={(e) => setCity(e.target.value)}
          />
          <label>
            City<span className="danger">*</span>
          </label>
        </Col>

        <Col sm={12} lg={6}>
          <Select
            className="mt-20"
            defaultValue={{ label: "Florida", value: "Florida" }}
            onChange={(selected) => setState(selected.value)}
            options={AppConstants().us_states}
          />
          <label>
            State<span className="danger">*</span>
          </label>
        </Col>
        <Col sm={12} lg={6}>
          <input
            type="number"
            className={`mt-20 ${
              formSubmit && (zip === "" || zip === null) ? "is-invalid" : ""
            }`}
            onChange={(e) => setZip(e.target.value)}
          />
          <label>
            Zip/Postal Number<span className="danger">*</span>
          </label>
        </Col>
      </Row>
      <hr />

      <Row>
        <Col sm={12} lg={6}>
          <input
            type={showPassword ? "text" : "password"}
            className={`mt-20 ${
              formSubmit && (lastname === "" || lastname === null)
                ? "is-invalid"
                : ""
            }`}
            onChange={(e) => setPassword(e.target.value)}
          />
          <label>
            Password<span className="danger">*</span>
          </label>

          <div className="form-check mt-20">
            <input
              className="form-check-input"
              type="checkbox"
              checked={showPassword}
              onClick={() => setShowPassword(!showPassword)}
              onChange={() => setShowPassword(!showPassword)}
            />
            <label
              className="form-check-label pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              Show Password
            </label>
          </div>
        </Col>
      </Row>

      <Row className="mt-50">
        <Col lg={12}>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={termsChecked}
              onClick={() => setTermsChecked(!termsChecked)}
              onChange={() => setTermsChecked(!termsChecked)}
            />
            <label
              className="form-check-label pointer"
              onClick={() => setShowTermsModal(true)}
            >
              Agree to <b>Terms and Conditions</b>
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={taxUpdates}
              onClick={() => setTaxUpdates(!taxUpdates)}
              onChange={() => setTaxUpdates(!taxUpdates)}
            />
            <label
              className="form-check-label pointer"
              onClick={() => setTaxUpdates(!taxUpdates)}
            >
              <i>Subscribe</i> to <b>Tax Updates</b>
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              checked={promoUpdates}
              onClick={() => setPromoUpdates(!promoUpdates)}
              onChange={() => setPromoUpdates(!promoUpdates)}
            />
            <label
              className="form-check-label pointer"
              onClick={() => setPromoUpdates(!promoUpdates)}
            >
              <i>Subscribe</i> to <b>Promotional Updates</b>
            </label>
          </div>

          <div className="mt-50 mb-3 mb-md-0">
            <center>
              <Button
                buttonStyle="btn--primary btn--large"
                onClick={() => handleValidation()}
              >
                Register Now
              </Button>
            </center>
          </div>
        </Col>
      </Row>

      <LoadingScreen
        show={showLoading}
        onHide={() => setShowLoading(false)}
        message={loadingMsg}
        redirect="false"
      />

      <Modal
        size="lg"
        show={showTermsModal}
        onHide={() => setShowTermsModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="primary">Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>Terms and Conditions Here</Modal.Body>
        <Modal.Footer>
          <Button
            className="btn--primary"
            onClick={() => {
              setTermsChecked(true);
              setShowTermsModal(false);
            }}
          >
            Accept
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default Registration;
