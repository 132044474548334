import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import useSession from "../hooks/useSession";

import "../styles/Navbar.css";

import { Button } from "./Button";

export const Navbar = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const { isAuth } = useSession();

  const showAuth = ["/"];

  return (
    <nav className="navbar">
      <Container>
        <div className="navbar-container">
          <Link to="/" className="navbar-logo">
            <img
              src="/images/income-tax-assistant-logo-no-background.png"
              alt="income-tax-assistant-navbar-logo"
            />
          </Link>
          <div className="menu-icon" onClick={() => setIsOpen(!isOpen)}>
            <i className={isOpen ? "fas fa-times" : "fas fa-bars"} />
          </div>
          <ul className={isOpen ? "nav-menu active" : "nav-menu"}>
            <li className="nav-item">
              <Link
                to="/"
                className="nav-links"
                onClick={() => setIsOpen(false)}
              >
                About
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/"
                className="nav-links"
                onClick={() => setIsOpen(false)}
              >
                Services
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/"
                className="nav-links"
                onClick={() => setIsOpen(false)}
              >
                Contact
              </Link>
            </li>
            {showAuth.some(
              (pathname) => pathname === window.location.pathname
            ) || !isAuth ? (
              <>
                <div>
                  <Button
                    onClick={() => {
                      navigate("/login");
                      setIsOpen(false);
                    }}
                    buttonStyle={
                      isOpen ? "btn--secondary-mobile" : "btn--secondary"
                    }
                  >
                    Login
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => {
                      navigate("/register");
                      setIsOpen(false);
                    }}
                    buttonStyle={
                      isOpen ? "btn--secondary-mobile" : "btn--primary"
                    }
                  >
                    Register Now
                  </Button>
                </div>
              </>
            ) : null}
          </ul>
        </div>
      </Container>
    </nav>
  );
};
