import React from "react";
import { Container, Form } from "react-bootstrap";

import { AppConstants } from "../AppConstants";

import questions from "../data/consultationQuestions.json";

export const ConsultationForm = ({
  formData,
  setFormData,
  formSubmit,
  readonly,
}) => {
  function questionInput(type, choices, name) {
    if (type === "radio") {
      return (
        <Form>
          {choices.map((choice) => {
            return (
              <>
                <Form.Check key={choice}>
                  <Form.Check.Input
                    id={`${choice}${name}`}
                    type="radio"
                    name={name}
                    onChange={setFormData}
                    value={choice}
                    checked={formData[name] === choice || ""}
                    readOnly={readonly}
                  />
                  <Form.Check.Label for={`${choice}${name}`}>
                    {choice}
                  </Form.Check.Label>
                </Form.Check>
              </>
            );
          })}
        </Form>
      );
    } else if (type === "number" || type === "phone") {
      return (
        <Form.Control
          type="number"
          min={0}
          max={choices}
          name={name}
          value={formData[name] || ""}
          onChange={setFormData}
          readOnly={readonly}
        />
      );
    } else if (type === "dropdown") {
      if (typeof choices === "number") {
        return (
          <Form.Select
            name={name}
            value={formData[name]}
            onChange={setFormData}
            readOnly={readonly}
          >
            <option hidden defaultValue>
              0
            </option>
            {[...Array(choices).keys()].map((choice) => {
              return (
                <option key={choice} value={choice + 1}>
                  {choice + 1}
                </option>
              );
            })}
          </Form.Select>
        );
      } else if (typeof choices === "object") {
        return (
          <Form.Select
            name={name}
            value={formData[name]}
            onChange={setFormData}
            readOnly={readonly}
          >
            <option hidden defaultValue>
              Select Item
            </option>
            {choices.map((choice) => {
              return (
                <option key={choice} value={choice}>
                  {choice}
                </option>
              );
            })}
          </Form.Select>
        );
      } else if (typeof choices === "string") {
        return (
          <Form.Select
            name={name}
            value={formData[name]}
            onChange={setFormData}
            readOnly={readonly}
          >
            <option hidden defaultValue>
              Select Item
            </option>
            {AppConstants()[choices].map(({ value }) => {
              return (
                <option key={value} value={value}>
                  {value}
                </option>
              );
            })}
          </Form.Select>
        );
      }
    } else if (type === "text") {
      return (
        <Form.Control
          type="text"
          min={0}
          max={choices}
          name={name}
          value={formData[name] || ""}
          onChange={setFormData}
          readOnly={readonly}
        />
      );
    }
  }

  function questionItem({
    question,
    number,
    note,
    type,
    choices,
    name,
    followUp,
    validation,
  }) {
    if (!followUp) {
      return (
        <>
          <label
            className={
              questionError({ name, type, validation }) ? "danger" : ""
            }
          >
            {number ? `${number}. ` : ""}
            {question}
            <span className="danger">*</span>
          </label>
          {note ? (
            <label className="d-block italic warning">Note: {note}</label>
          ) : null}
          {questionInput(type, choices, name)}
        </>
      );
    } else {
      if (formData[followUp.from] === followUp.accepted) {
        return (
          <>
            <label
              className={`${
                questionError({ name, type, validation }) ? "danger" : ""
              }`}
            >
              {question}
              <span className="danger">*</span>
            </label>
            {questionInput(type, choices, name)}
          </>
        );
      }
    }
  }

  function questionError(question) {
    if (question.validation && question.validation.min !== null) {
      return (
        (formSubmit && !formData[question.name]) ||
        (formData[question.name] &&
          parseInt(formData[question.name]) <= question.validation.min)
      );
    } else if (question.validation && question.validation.exact !== null) {
      return (
        (formSubmit && !formData[question.name]) ||
        (formData[question.name] &&
          formData[question.name].toString().length !==
            question.validation?.exact)
      );
    } else if (question.name === "taxYears") {
      const regex = new RegExp("^[0-9]*([,][0-9\\s]+)*$");

      return (
        (formSubmit && !formData[question.name]) ||
        !regex.test(formData[question.name])
      );
    } else {
      return formSubmit && !formData[question.name];
    }
  }
  return (
    <>
      {questions.map((question, index) => {
        if (question.break) {
          return <hr key={index} />;
        }
        return (
          <Container fluid className={index !== 0 ? "mt-20" : ""} key={index}>
            {questionItem(question)}
          </Container>
        );
      })}
    </>
  );
};
