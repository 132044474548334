export const AppConstants = () => {
  let appName = "Income Tax Assistant";
  // let env = 'production';
  let env = "dev";
  // let env = 'dev-live';

  let _baseURL = "";
  let _baseDBURL = "";
  let _awsBucketUrl = "";

  const primaryColor = "#1D8843";
  const secondaryColor = "#BB892D";
  const tertiaryColor = "#2a2623";

  if (env === "production") {
    _baseDBURL = process.env.REACT_APP_PROD_DB_BASE_URL;
    _baseURL = process.env.REACT_APP_PROD_BASE_URL;
    _awsBucketUrl = process.env.REACT_APP_DEV_BUCKET_URL;
  } else if (env === "dev-live") {
    _baseDBURL = process.env.REACT_APP_LIVEDEV_DB_BASE_URL;
    _baseURL = process.env.REACT_APP_LIVEDEV_BASE_URL;
    _awsBucketUrl = process.env.REACT_APP_DEV_BUCKET_URL;
  } else {
    _baseDBURL = process.env.REACT_APP_DB_BASE_URL;
    _baseURL = process.env.REACT_APP_BASE_URL;
    _awsBucketUrl = process.env.REACT_APP_DEV_BUCKET_URL;
  }

  const daysOfWeek = [
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
  ];

  const windowTiming = [
    /* { label: "11:00 am", value: "11:00 am" },
    { label: "11:40 am", value: "11:40 am" },
    { label: "12:20 pm", value: "12:20 pm" },
    { label: "1:00 pm", value: "1:00 pm" },
    { label: "1:40 pm", value: "1:40 pm" },
    { label: "2:20 pm", value: "2:20 pm" },
    { label: "3:00 pm", value: "3:00 pm" },
    { label: "3:40 pm", value: "3:40 pm" },
    { label: "4:20 pm", value: "4:20 pm" },
    { label: "5:00 pm", value: "5:00 pm" },
    { label: "5:40 pm", value: "5:40 pm" },
    { label: "6:20 pm", value: "6:20 pm" },
    { label: "7:00 pm", value: "7:00 pm" }, */
    { label: "8:00 am - 12:00 pm", value: "8:00 am - 12:00 pm" },
    { label: "12:00 pm - 4:00 pm", value: "12:00 pm - 4:00 pm" },
    { label: "4:00 pm - 8:00 pm", value: "4:00 am - 8:00 pm" },
  ];

  const use_states = [
    { label: "Alaska", value: "Alaska" },
    { label: "Alabama", value: "Alabama" },
    { label: "Arkansas", value: "Arkansas" },
    { label: "Arizona", value: "Arizona" },
    { label: "California", value: "California" },
    { label: "Colorado", value: "Colorado" },
    { label: "Connecticut", value: "Connecticut" },
    { label: "District of Columbia", value: "District of Columbia" },
    { label: "Delaware", value: "Delaware" },
    { label: "Florida", value: "Florida", isSelected: true },
    { label: "Georgia", value: "Georgia" },
    { label: "Hawaii", value: "Hawaii" },
    { label: "Iowa", value: "Iowa" },
    { label: "Idaho", value: "Idaho" },
    { label: "IL", value: "Illinois" },
    { label: "Illinois", value: "Indiana" },
    { label: "Kansas", value: "Kansas" },
    { label: "Kentucky", value: "Kentucky" },
    { label: "Louisiana", value: "Louisiana" },
    { label: "Massachusetts", value: "Massachusetts" },
    { label: "Maryland", value: "Maryland" },
    { label: "Maine", value: "Maine" },
    { label: "Michigan", value: "Michigan" },
    { label: "Minnesota", value: "Minnesota" },
    { label: "Missouri", value: "Missouri" },
    { label: "Mississippi", value: "Mississippi" },
    { label: "Montana", value: "Montana" },
    { label: "North Carolina", value: "North Carolina" },
    { label: "North Dakota", value: "North Dakota" },
    { label: "Nebraska", value: "Nebraska" },
    { label: "New Hampshire", value: "New Hampshire" },
    { label: "New Jersey", value: "New Jersey" },
    { label: "New Mexico", value: "New Mexico" },
    { label: "Nevada", value: "Nevada" },
    { label: "New York", value: "New York" },
    { label: "Ohio", value: "Ohio" },
    { label: "Oklahoma", value: "Oklahoma" },
    { label: "Oregon", value: "Oregon" },
    { label: "Pennsylvania", value: "Pennsylvania" },
    { label: "Rhode Island", value: "Rhode Island" },
    { label: "South Carolina", value: "South Carolina" },
    { label: "South Dakota", value: "South Dakota" },
    { label: "Tennessee", value: "Tennessee" },
    { label: "Texas", value: "Texas" },
    { label: "Utah", value: "Utah" },
    { label: "Virginia", value: "Virginia" },
    { label: "Vermont", value: "Vermont" },
    { label: "Washington", value: "Washington" },
    { label: "Wisconsin", value: "Wisconsin" },
    { label: "West Virginia", value: "West Virginia" },
    { label: "Wyoming", value: "Wyoming" },
  ];

  const letterOfEngagement = (
    <>
      <p>
        This letter will confirm our recent discussion regarding the scope and
        terms of our engagement.{" "}
      </p>
      <p>
        Our firm has agreed to represent you in [describe matter]. While I will
        be personally responsible for supervising this legal matter, I
        anticipate that other lawyers and legal assistants in the firm will also
        be working on this matter.{" "}
      </p>
      <p>
        We will undertake the following work on your behalf [detail anticipated
        services by clearly defining and limiting scope of representation].{" "}
      </p>
      <p>
        [Straight hourly option] You have agreed to pay for our services based
        on the time we spend working on the case. My current hourly rate is
        $_____ per hour. The rates of our associates currently range between
        $_____ and $_____ per hour. Legal assistants, who will be utilized where
        appropriate to avoid unnecessary attorney fees, are charged at $_____
        per hour. These rates are subject to change once a year, usually in
        December. Generally you will be billed for all time spent on your
        matter, including telephone calls and email.{" "}
      </p>
      <p>
        [Value added billing option] You have agreed to pay for our services
        based on the time we spend working on your case, with allowance for
        reduction or increase in fees under certain circumstances. My current
        hourly rate is $_____ per hour. The rates of our associates currently
        range between $_____ and $_____ per hour. Legal assistants, who will be
        utilized where appropriate to avoid unnecessary attorney fees, are
        charged at $_____ per hour. These rates are subject to change once a
        year, usually in December. On occasion, time may be written off before a
        statement is sent because we feel there has been some degree of
        inefficiency in the work or for other reasons. On the other hand, fees
        may be raised above hourly rate levels, based on the complexity of the
        matter, superior results, or other factors. If applied, we will discuss
        any such increases with you, and believe you will find them appropriate.
      </p>
      <p>
        We will forward billing statements monthly. They will contain a
        description of services, including the date, the person rendering the
        service, the amount of time involved, and a description of the task
        accomplished. Monthly statements will also itemize monies we have
        advanced on your behalf, such as service and filing fees, expert witness
        fees, court reporter fees, and charges for investigation, travel and
        accommodation, telephone long distance, photocopies and telecopies.{" "}
      </p>
    </>
  );

  return {
    base_url: _baseURL,
    db_base_url: _baseDBURL,
    bucket_url: _awsBucketUrl,
    app_name: appName,
    days_of_week: daysOfWeek,
    window_timing: windowTiming,
    us_states: use_states,
    primary_color: primaryColor,
    secondary_color: secondaryColor,
    tertiary_color: tertiaryColor,
    letterOfEngagement,
  };
};
