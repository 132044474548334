import { useEffect, useState } from "react";

function useStopwatch({ returnTime }) {
  const [time, setTime] = useState(0);
  const [running, setRunning] = useState(false);

  useEffect(() => {
    let interval;
    if (running) {
      interval = setInterval(() => {
        setTime((prevTime) => prevTime + 10);
      }, 10);
    } else if (!running) {
      returnTime(time);
      clearInterval(interval);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [running]);

  return {
    running,
    setRunning,
    hours: ("0" + Math.floor((time / 6000000) % 60)).slice(-2),
    minutes: ("0" + Math.floor((time / 60000) % 60)).slice(-2),
    seconds: ("0" + Math.floor((time / 1000) % 60)).slice(-2),
    milliseconds: ("0" + ((time / 10) % 100)).slice(-2),
    formatted: `${("0" + Math.floor((time / 6000000) % 60)).slice(-2)}:${(
      "0" + Math.floor((time / 60000) % 60)
    ).slice(-2)}:${("0" + Math.floor((time / 1000) % 60)).slice(-2)}`,
    duration: time,
    setDuration: (time) => setTime(time),
  };
}

export default useStopwatch;
