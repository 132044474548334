import React from "react";
import { Button, Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { Header } from "../../components";
import useQuery from "../../hooks/useQuery";

function Checkout() {
  const { status } = useParams();

  const query = useQuery();

  return (
    <Container>
      <Header title={"Payment Recieved"} />
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{ marginTop: "100px" }}
      >
        <h1 className="primary">
          {status === "success"
            ? "Thank you we have received your payment"
            : "You have canceled your payment"}
        </h1>
        <Link
          to={`/consultation/${query.get("submission_id")}`}
          className="mt-5"
        >
          <Button className="rounded">Go Back</Button>
        </Link>
      </div>
    </Container>
  );
}

export default Checkout;
