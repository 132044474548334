import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Header } from "../../components";
import { UtilitiesContext } from "../../context/UtilitiesContext";
import useQuery from "../../hooks/useQuery";
import { changePassword, forgotPassword } from "../../service/authService";

function ForgotPassword() {
  const { setModal, setLoadingScreen } = useContext(UtilitiesContext);
  const { content } = useParams(); //forgot-password, change-password
  const navigate = useNavigate();
  const query = useQuery();

  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const [updatePasswordForm, setUpdatePasswordForm] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  function handleResetPasswordLink() {
    if (email) {
      setLoadingScreen({
        isLoading: true,
        message: "Sending reset link to your email",
      });
      forgotPassword(email)
        .then((res) => {
          setLoadingScreen({
            isLoading: false,
            message: "",
          });
          setModal({
            display: true,
            message:
              "Please check your email, we have sent the reset password link",
            action: () => {
              setModal({
                display: false,
                message: "",
                action: null,
              });
            },
          });
        })
        .catch((e) => {
          setLoadingScreen({
            isLoading: false,
            message: "",
          });
          console.log(e);
        });
    } else {
      setModal({
        display: true,
        message: "Please provide email",
        action: () => {
          setModal({
            display: false,
            message: "",
            action: null,
          });
        },
      });
    }
  }

  function handleChangePassword(action) {
    if (updatePasswordForm.newPassword !== updatePasswordForm.confirmPassword) {
      setModal({
        display: true,
        message: "Password didn't match, please enter the correct values",
        action: () => {
          setModal({
            display: false,
            message: "",
            action: null,
          });
        },
      });
    } else if (
      !updatePasswordForm.newPassword ||
      !updatePasswordForm.confirmPassword
    ) {
      setModal({
        display: true,
        message: "Please provide values",
        action: () => {
          setModal({
            display: false,
            message: "",
            action: null,
          });
        },
      });
    } else {
      setLoadingScreen({
        isLoading: true,
        message: "Updating your password",
      });
      changePassword({
        token,
        password: updatePasswordForm.newPassword,
      })
        .then((res) => {
          if (action === "update") {
            setLoadingScreen({
              isLoading: false,
              message: "",
            });
            setModal({
              display: true,
              message: "Password has been updated",
              actionLabel: "Login",
              action: () => {
                navigate("/login");
                setModal({
                  display: false,
                  message: "",
                  action: null,
                  actionLabel: "",
                });
              },
            });
          } else if (action === "login") {
            setLoadingScreen({
              isLoading: false,
              message: "",
            });
            navigate("/login");
          }
        })
        .catch((e) => {
          setLoadingScreen({
            isLoading: false,
            message: "",
          });
          console.log(e);
        });
    }
  }

  useEffect(() => {
    setToken(query.get("token"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  const displayContent = () => {
    if (content === "forgot-password") {
      return (
        <Form className="">
          <Form.Label>Enter your email</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            className="rounded mx-auto mt-4 text-center d-block"
            onClick={handleResetPasswordLink}
          >
            Send Reset Password Link
          </Button>
        </Form>
      );
    } else if (content === "change-password") {
      return (
        <Form className="">
          <Form.Label>Create new password</Form.Label>
          <Form.Control
            type="password"
            value={updatePasswordForm.newPassword}
            onChange={(e) =>
              setUpdatePasswordForm((prevState) => ({
                ...prevState,
                newPassword: e.target.value,
              }))
            }
          />
          <Form.Label className="mt-3">Confirm new password</Form.Label>
          <Form.Control
            type="password"
            value={updatePasswordForm.confirmPassword}
            onChange={(e) =>
              setUpdatePasswordForm((prevState) => ({
                ...prevState,
                confirmPassword: e.target.value,
              }))
            }
          />
          <div className="d-flex gap-2 mt-4">
            {/* <Button
              className="rounded"
              onClick={() => handleChangePassword("login")}
            >
              Login
            </Button> */}
            <Button
              className="rounded"
              onClick={() => handleChangePassword("update")}
            >
              Update Password
            </Button>
          </div>
        </Form>
      );
    }
  };

  return (
    <Container>
      <Row>
        <Col className="mt-5">
          <center>
            <h1 className="primary">Reset Password</h1>
          </center>
          <div className="w-50 mt-5 border border-primary p-4 rounded mx-auto">
            {displayContent()}
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ForgotPassword;
