import React, { useState } from "react";
import { Button, Container, Form } from "react-bootstrap";

export const QuickQuoteQuestionnaire = ({
  formData,
  setFormData,
  serviceType,
  setServiceType,
  calculate,
}) => {
  const [allowCalculate, setAllowCalculate] = useState(false);

  function questionnaires() {
    if (serviceType?.name === "Individual") {
      return (
        <>
          <Container className="mt-20">
            <label>
              2. Type of Filing
              <span className="danger">*</span>
            </label>
            {[
              {
                name: "Single",
                value: 150,
              },
              {
                name: "Married Filing Jointly",
                value: 225,
              },
              {
                name: "Married Filing Separately",
                value: 150,
              },
              {
                name: "Head of Household",
                value: 150,
              },
            ].map((option) => {
              return (
                <Form.Check
                  type="radio"
                  name="filingType"
                  key={option.name}
                  label={option.name}
                  checked={formData["filingType"]?.name === option.name}
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: {
                        name: option.name,
                        value: option.value,
                        question: "Type of Filing",
                      },
                    }));
                  }}
                />
              );
            })}
          </Container>
          {formData["filingType"] ? (
            <Container className="mt-20">
              <label>
                3.{" "}
                {formData["filingType"]?.name === "Married Filing Jointly" ||
                formData["filingType"]?.name === "Married Filing Separately"
                  ? "Do you or your spouse/partner own a business?"
                  : "Do you own a business?"}
                <span className="danger">*</span>
              </label>
              {[
                { name: "Yes", value: 100 },
                { name: "No", value: 0 },
              ].map((option) => {
                return (
                  <Form.Check
                    type="radio"
                    name="ownBusiness"
                    key={option.name}
                    label={option.name}
                    checked={formData["ownBusiness"]?.name === option.name}
                    onChange={(e) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        [e.target.name]: {
                          name: option.name,
                          value: option.value,
                          question:
                            formData["filingType"]?.name ===
                              "Married Filing Jointly" ||
                            formData["filingType"]?.name ===
                              "Married Filing Separately"
                              ? "Do you or your spouse/partner own a business?"
                              : "Do you own a business?",
                        },
                      }));
                    }}
                  />
                );
              })}
            </Container>
          ) : null}
          {formData["ownBusiness"] ? (
            <Container className="mt-20">
              <label>
                4. Do you think you need to file for an extension this year?
                <span className="danger">*</span>
              </label>
              {[
                { name: "Yes", value: 50 },
                { name: "No", value: 0 },
              ].map((option) => {
                return (
                  <Form.Check
                    type="radio"
                    name="fileExtension"
                    key={option.name}
                    label={option.name}
                    checked={formData["fileExtension"]?.name === option.name}
                    onChange={(e) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        [e.target.name]: {
                          name: option.name,
                          value: option.value,
                          question:
                            "Do you think you need to file for an extension this year?",
                        },
                      }));
                      setAllowCalculate(true);
                    }}
                  />
                );
              })}
            </Container>
          ) : null}
        </>
      );
    } else if (serviceType?.name === "Business") {
      return (
        <>
          <Container className="mt-20">
            <label>
              2. Do you have a tax ID/EIN for your business?
              <span className="danger">*</span>
            </label>
            {[
              { name: "Yes", value: 0 },
              { name: "No", value: 0 },
            ].map((option) => {
              return (
                <Form.Check
                  type="radio"
                  name="haveTaxIdEin"
                  key={option.name}
                  label={option.name}
                  checked={formData["haveTaxIdEin"]?.name === option.name}
                  onChange={(e) => {
                    if (option.name === "Yes") {
                      setFormData((prevState) => ({
                        ...prevState,
                        [e.target.name]: {
                          name: option.name,
                          value: option.value,
                          question:
                            "Do you have a tax ID/EIN for your business?",
                        },
                      }));
                    } else {
                      setFormData((prevState) => ({
                        ...prevState,
                        [e.target.name]: {
                          name: option.name,
                          value: option.value,
                          question:
                            "Do you have a tax ID/EIN for your business?",
                        },
                        businessType: {
                          name: "Sole Proprietorship",
                          value: 0,
                          question: "What Type of Business",
                        },
                      }));
                    }
                  }}
                />
              );
            })}
          </Container>
          {formData["haveTaxIdEin"] ? (
            <Container className="mt-20">
              <label>
                3. What Type of Business
                <span className="danger">*</span>
              </label>
              {[
                { name: "LLC", value: 0 },
                { name: "General Corporation", value: 0 },
                { name: "Sub-S Corporation", value: 0 },
                { name: "Partnership", value: 0 },
                { name: "Sole Proprietorship", value: 0 },
              ].map((option) => {
                return (
                  <Form.Check
                    type="radio"
                    name="businessType"
                    key={option.name}
                    label={option.name}
                    checked={formData["businessType"]?.name === option.name}
                    onChange={(e) => {
                      if (formData["fileExtension"]) {
                        setFormData((prevState) => ({
                          ...prevState,
                          [e.target.name]: {
                            name: option.name,
                            value: option.value,
                            question: "What Type of Business",
                          },
                          fileExtension: {
                            ...prevState.fileExtension,
                            value:
                              formData["businessType"].name &&
                              formData["businessType"].name ===
                                "Sole Proprietorship"
                                ? 50
                                : 75,
                          },
                        }));
                      } else {
                        setFormData((prevState) => ({
                          ...prevState,
                          [e.target.name]: {
                            name: option.name,
                            value: option.value,
                            question: "What Type of Business",
                          },
                        }));
                      }
                    }}
                  />
                );
              })}
            </Container>
          ) : null}
          {formData["businessType"] ? (
            <Container className="mt-20">
              <label>
                4. Do you think you need to file for an extension this year?
                <span className="danger">*</span>
              </label>
              {[
                {
                  name: "Yes",
                  value:
                    formData["businessType"].name &&
                    formData["businessType"].name === "Sole Proprietorship"
                      ? 50
                      : 75,
                },
                { name: "No", value: 0 },
              ].map((option) => {
                return (
                  <Form.Check
                    type="radio"
                    name="fileExtension"
                    key={option.name}
                    label={option.name}
                    checked={formData["fileExtension"]?.name === option.name}
                    onChange={(e) => {
                      setFormData((prevState) => ({
                        ...prevState,
                        [e.target.name]: {
                          name: option.name,
                          value: option.value,
                          question:
                            "Do you think you need to file for an extension this year?",
                        },
                      }));
                      setAllowCalculate(true);
                    }}
                  />
                );
              })}
            </Container>
          ) : null}
        </>
      );
    } else if (
      serviceType?.name === "Trust" ||
      serviceType?.name === "Estate"
    ) {
      return (
        <>
          <Container className="mt-20">
            <label>
              4. Do you think you need to file for an extension this year?
              <span className="danger">*</span>
            </label>
            {[
              { name: "Yes", value: 50 },
              { name: "No", value: 0 },
            ].map((option) => {
              return (
                <Form.Check
                  type="radio"
                  name="fileExtension"
                  key={option.name}
                  label={option.name}
                  checked={formData["fileExtension"]?.name === option.name}
                  onChange={(e) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      [e.target.name]: {
                        name: option.name,
                        value: option.value,
                        question:
                          "Do you think you need to file for an extension this year?",
                      },
                    }));
                    setAllowCalculate(true);
                  }}
                />
              );
            })}
          </Container>
        </>
      );
    }
  }

  return (
    <Container className="quick-quote">
      <Container>
        <label>
          1. Type of Service
          <span className="danger">*</span>
        </label>
        <Form.Select
          name="serviceType"
          value={serviceType?.name}
          onChange={(e) => {
            let value = 0;
            if (e.target.value === "Business") {
              value = 100;
            } else if (
              e.target.value === "Estate" ||
              e.target.value === "Trust"
            ) {
              value = 250;
            } else {
              value = 0;
            }

            setServiceType({
              name: e.target.value,
              value: value,
            });
            setFormData({});
            setAllowCalculate(false);
          }}
        >
          <option hidden defaultValue>
            Select Item
          </option>
          {["Individual", "Business", "Estate", "Trust"].map((service) => {
            return (
              <option key={service} value={service}>
                {service}
              </option>
            );
          })}
        </Form.Select>
      </Container>
      {questionnaires()}
      {allowCalculate ? (
        <div className="d-flex justify-content-center mt-3">
          <Button className=" rounded" variant="success" onClick={calculate}>
            Calculate
          </Button>
        </div>
      ) : null}
    </Container>
  );
};
