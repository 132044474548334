import { useContext, useEffect, useState } from "react";

import { Button } from "react-bootstrap";
import * as FiIcons from "react-icons/fi";

import { useNavigate } from "react-router-dom";

import * as FaIcons from "react-icons/fa";
import { payConsultation } from "../../service/consultationService";
import { UtilitiesContext } from "../../context/UtilitiesContext";
import JSZip from "jszip";
import JSZipUtils from "../../JSZipUtils";
import { saveAs } from "file-saver";

export const useConsultation = ({
  setConsultationTime,
  setConsultationNotes,
  consultationData,
  consultationStopwatch,
  setConsultaionStopwatchStart,
  setConsultationCallDuration,
  updateConsultationData,
  viewRejections,
  openUnderProcessingModal,
  openUploadTaxReturnModal,
  openInvoiceModal,
}) => {
  const navigate = useNavigate();

  const { setLoadingScreen } = useContext(UtilitiesContext);

  const [userType] = useState(localStorage.getItem("user_type"));
  const [loaded, setLoaded] = useState();
  const [consultationMessage, setConsultationMessage] = useState({
    content: null,
    color: null,
    action: null,
  });

  const startConsultation = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmStartConsultation = confirm(
      "Reminder: Prevent redirecting while in call to prevent loss of data\n\nAre you sure you want to continue?"
    );
    if (confirmStartConsultation) {
      updateConsultationData("startConsultation");
      setConsultationTime((prevState) => ({
        ...prevState,
        show: true,
      }));
      setConsultationNotes({
        display: true,
        toggleable: true,
      });
      setConsultaionStopwatchStart(true);
    }
  };

  function downloadFiles(files) {
    let zip = new JSZip();
    let count = 0;

    setLoadingScreen({
      isLoading: true,
      message: "Downloading Files",
    });

    files.forEach(async (file, index) => {
      try {
        const fileToDownload = await JSZipUtils.getBinaryContent(
          file.download_url
        );
        zip.file(file.filename, fileToDownload, { binary: true });
        count++;
        if (count === files.length) {
          zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, "files.zip");
          });
          setLoadingScreen({
            isLoading: false,
            message: "",
          });
        }
      } catch (error) {
        console.log(error);
      }
    });
  }

  function handlePayConsultation() {
    setLoadingScreen({
      isLoading: true,
      message: "You will be redirected shortly",
    });
    payConsultation(consultationData.id)
      .then((res) => {
        setLoadingScreen({
          isLoading: false,
          message: "",
        });
      })
      .catch((e) => {
        console.log(e);
        setLoadingScreen({
          isLoading: false,
          message: "",
        });
      });
  }

  function setConsultationMessageByUser(adminMessage, clientMessage) {
    if (userType === "admin") {
      setConsultationMessage(adminMessage);
    } else {
      setConsultationMessage(clientMessage);
    }
  }

  function forConsultationMessage() {
    if (consultationData.submission_answers) {
      if (
        consultationData.submission_answers.find(
          ({ question_code }) => question_code === "isAlwaysAvailable"
        ).answer.value === "Yes"
      ) {
        return "Prepare for Consultation";
      } else {
        return `${
          consultationData.submission_answers.find(
            ({ question_code }) => question_code === "availableDay"
          ).answer.value
        } ${
          consultationData.submission_answers.find(
            ({ question_code }) => question_code === "availableTime"
          ).answer.value
        }`;
      }
    }
  }

  useEffect(() => {
    if (consultationData.submission_answers) {
      if (
        consultationData.submission_answers.find(
          ({ question_code }) => question_code === "isAlwaysAvailable"
        ).answer.value === "Yes"
      ) {
        // console.log("Prepare for Consultation");
      }
    }
  }, [consultationData]);

  useEffect(() => {
    setConsultationCallDuration(consultationData.duration);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  useEffect(() => {
    if (Object.keys(consultationData).length && !loaded) {
      setLoaded(true);
    }

    switch (consultationData.status) {
      case "closed":
        setConsultationMessageByUser(
          {
            content: "This consultation is closed",
            color: "light",
          },
          {
            content: "This consultation is closed",
            color: "light",
          }
        );
        break;

      case "forNotification":
        setConsultationMessageByUser(
          {
            content: "This consultation hasn't been accepted",
            action: (
              <>
                <Button
                  className="rounded flex-grow-1  align-self-center"
                  variant="primary"
                  onClick={() => updateConsultationData("forConsultation")}
                >
                  Accept
                </Button>
                <Button
                  className="rounded flex-grow-1  align-self-center"
                  variant="danger"
                  onClick={() => updateConsultationData("closed")}
                >
                  Close
                </Button>
              </>
            ),
          },
          {
            content: "Sent for Tax Advisor to Review",
          }
        );
        break;

      case "forConsultation":
        setConsultationMessageByUser(
          {
            content:
              "Client has been notified. You can now start your consultation anytime.",
            action: (
              <>
                <Button
                  className="rounded flex-grow-1"
                  variant="primary"
                  onClick={startConsultation}
                >
                  Start Consultation
                </Button>
                <Button
                  className="rounded flex-grow-1  align-self-center"
                  variant="danger"
                  onClick={() => updateConsultationData("closed")}
                >
                  Close
                </Button>
              </>
            ),
          },
          {
            content: forConsultationMessage(),
          }
        );
        break;

      case "startConsultation":
        setConsultationMessageByUser(
          {
            content: `Consultation Ongoing`,
            //consultation starting
            action: (
              <>
                <Button
                  className="rounded flex-grow-1"
                  variant="primary"
                  onClick={() => {
                    setConsultaionStopwatchStart(false);
                    updateConsultationData("endConsultation");
                  }}
                >
                  End Consultation
                </Button>
              </>
            ),
          },
          {
            content: `Consultation Ongoing`,
            color: "info",
          }
        );
        setConsultationTime((prevState) => ({
          ...prevState,
          show: true,
        }));
        break;

      case "endConsultation":
        setConsultationMessageByUser(
          {
            content: `Consultation ended`,
            // Consutation Ended
            action: (
              <>
                <Button
                  className="rounded flex-grow-1"
                  variant="primary"
                  onClick={startConsultation}
                >
                  Start Consultation
                </Button>
                <Button
                  className="rounded flex-grow-1"
                  variant="primary"
                  onClick={() => {
                    updateConsultationData("allowUpload");
                    setConsultationTime({
                      show: false,
                      value: consultationStopwatch,
                    });
                  }}
                >
                  Allow Upload
                </Button>
                <Button
                  className="rounded flex-grow-1  align-self-center"
                  variant="danger"
                  onClick={() => updateConsultationData("closed")}
                >
                  Close
                </Button>
              </>
            ),
          },
          {
            content: `Consultation ended, prepare your documents to upload`,
          }
        ); // end stopwatch & display time
        setConsultationTime((prevState) => ({
          ...prevState,
          show: true,
        }));
        break;

      case "allowUpload":
        setConsultationMessageByUser(
          {
            content: "Waiting for uploaded documents",
          },
          {
            content: "You can now upload documents",
            // You can now upload documents
            action: (
              <Button
                className="rounded flex-grow-1"
                variant="primary"
                onClick={() => navigate("files")}
              >
                <FiIcons.FiUpload className="me-2" />
                Upload
              </Button>
            ),
          }
        );
        break;

      case "receivedUpload":
        setConsultationMessageByUser(
          {
            content: "Documents Uploaded",
            // Documents Upload, please as your tax advisro reviews your documents
            action: (
              <Button
                className="rounded flex-grow-1"
                variant="primary"
                onClick={() => {
                  navigate("files");
                }}
              >
                View Files
              </Button>
            ),
          },
          {
            content:
              "Documents Uploaded, please wait as your tax advisor reviews your documents",
            action: (
              <Button
                className="rounded flex-grow-1"
                variant="primary"
                onClick={() => {
                  navigate("files");
                }}
              >
                View Files
              </Button>
            ),
          }
        );
        break;

      case "rejected":
        setConsultationMessageByUser(
          {
            content:
              "Client's documents was sent back for additional information. Waiting for new documents.",
            color: "danger",
          },
          {
            content:
              "Your uploaded documents is missing something or has an incorrect file. Please view the additional information message sent by Income Tax Assistant, and upload all required files.",
            color: "danger",
            action: (
              <>
                <Button
                  className="rounded flex-grow-1"
                  variant="danger"
                  onClick={viewRejections}
                >
                  Additional Information
                </Button>
                <Button
                  className="rounded flex-grow-1"
                  variant="primary"
                  onClick={() => navigate("files")}
                >
                  Upload
                </Button>
              </>
            ),
          }
        );
        break;

      case "underProcess":
        setConsultationMessageByUser(
          {
            content:
              "Client documents reviewed and accepted. Tax return preparations is now under process.",
            color: "",
            action: (
              <>
                <Button
                  className="rounded flex-grow-1"
                  variant="primary"
                  onClick={openUnderProcessingModal}
                >
                  Generate Invoice
                </Button>
                <Button
                  className="rounded flex-grow-1"
                  variant="primary"
                  onClick={() => {
                    navigate("files");
                  }}
                >
                  View Files
                </Button>
              </>
            ),
          },
          {
            content: "Documents reviewed, this consultation is under process",
            color: "",
            action: (
              <Button
                className="rounded flex-grow-1"
                variant="primary"
                onClick={() => {
                  navigate("files");
                }}
              >
                View Files
              </Button>
            ),
          }
        );
        break;

      case "waitingPayment":
        setConsultationMessageByUser(
          {
            content:
              "Tax return calculations and invoice was sent to the client. Waiting for client's payment to proceed.",
            action: (
              <Button
                className="rounded flex-grow-1"
                variant="primary"
                onClick={() => {
                  openInvoiceModal();
                }}
              >
                View Invoice
              </Button>
            ),
          },
          {
            content: "Invoice is ready, you can proceed to pay with Stripe",
            action: (
              <>
                <Button
                  className="rounded flex-grow-1"
                  variant="primary"
                  onClick={() => {
                    openInvoiceModal();
                  }}
                >
                  View Invoice
                </Button>
                <Button
                  className="rounded flex-grow-1 d-flex gap-2 align-items-center"
                  style={{ backgroundColor: "#5433FF" }}
                  onClick={handlePayConsultation}
                >
                  <FaIcons.FaStripeS />
                  <div
                    className="align-self-stretch bg-white my-1"
                    style={{ width: "1px" }}
                  />
                  Pay with Stripe
                </Button>
              </>
            ),
          }
        );
        break;

      case "waitingTaxReturn":
        setConsultationMessageByUser(
          {
            content: "Client's payment received. Please upload the tax return.",
            action: (
              <Button
                className="rounded flex-grow-1"
                variant="primary"
                onClick={() => {
                  openUploadTaxReturnModal();
                }}
              >
                Upload Tax Return
              </Button>
            ),
          },
          {
            content:
              "Payment recieved, please wait while your tax advisor uploads your tax return",
          }
        );
        break;

      case "readyTaxReturn":
        setConsultationMessageByUser(
          {
            content:
              "Tax return successfully uploaded and is available for the client to download. This consultation is now complete.",
            color: "success",
          },
          {
            content: "Tax return uploaded, download available",
            color: "success",
            action: (
              <Button
                className="rounded flex-grow-1"
                variant="primary"
                onClick={() => {
                  downloadFiles([
                    ...consultationData.tax_returns,
                    ...consultationData.submission_files,
                    consultationData.invoice,
                  ]);
                  updateConsultationData("completed");
                }}
              >
                Download Tax Return
              </Button>
            ),
          }
        );
        break;

      case "completed":
        setConsultationMessageByUser(
          {
            content: "Client downloaded tax return",
            color: "success",
          },
          {
            content:
              "You have successfully downloaded your tax return. This consultation is now complete. Thank you for using Income Tax Assistant!",
            color: "success",
          }
        );
        break;

      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [consultationData]);

  return {
    consultationMessage,
  };
};
