import axios from "axios";

import { config } from "../hooks/useAxios";

const API_URL = `${process.env.REACT_APP_DB_BASE_URL}me/`;

export const getMe = async () => {
  const response = await axios.get(
    `${API_URL}info`,
    config(localStorage.getItem("token"))
  );

  return response;
};

export const updateMe = async (data) => {
  const response = await axios.put(
    `${API_URL}update`,
    data,
    config(localStorage.getItem("token"))
  );

  return response;
};
