import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import { Header, RejectionModal } from "../../components";

import * as FiIcons from "react-icons/fi";
import * as MdIcons from "react-icons/md";
import * as SiIcons from "react-icons/si";
import { UtilitiesContext } from "../../context/UtilitiesContext";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { useNavigate, useParams } from "react-router-dom";
import {
  getConsultations,
  updateConsultation,
  upload,
} from "../../service/consultationService";

import { AppConstants } from "../../AppConstants";

import JSZipUtils from "../../JSZipUtils";
import moment from "moment-timezone";
import { getMe } from "../../service/profileService";

function ConsultationFiles() {
  const navigate = useNavigate();

  const { id } = useParams();

  const { setModal, setLoadingScreen, loadingScreen } =
    useContext(UtilitiesContext);

  const uploadRef = useRef();

  const [newFiles, setNewFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [status, setStatus] = useState([]);
  const [user, setUser] = useState([]);
  const [taxYears, setTaxYears] = useState();

  const [userType] = useState(localStorage.getItem("user_type"));
  const [displayFileModal, setDisplayFileModal] = useState({
    isOpen: false,
    file: null,
  });
  const [letterOfEngagement, setLetterOfEngagement] = useState({
    display: true,
    accepted: true,
  });
  const [rejectionModal, setRejectionModal] = useState({
    isOpen: false,
  });

  function deleteFile(filename) {
    setNewFiles(newFiles.filter((file) => file.name !== filename));
  }

  function downloadFile(url) {
    const a = document.createElement("a");
    a.href = url;
    a.download = url.split("/").pop();
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  function downloadFiles() {
    let zip = new JSZip();
    let count = 0;

    setLoadingScreen({
      isLoading: true,
      message: "Downloading Files",
    });

    uploadedFiles.forEach(async (file, index) => {
      try {
        const fileToDownload = await JSZipUtils.getBinaryContent(
          file.download_url
        );
        zip.file(file.filename, fileToDownload, { binary: true });
        count++;
        if (count === uploadedFiles.length) {
          zip.generateAsync({ type: "blob" }).then(function (content) {
            saveAs(content, "files.zip");
          });
          setLoadingScreen({
            isLoading: false,
            message: "",
          });
        }
      } catch (error) {
        console.log(error);
      }
    });
  }

  function updateConsultationData(id, status, back = false) {
    if (status === "underProcess" || status === "rejected") {
      setModal({
        display: true,
        message: `Are you sure you want to ${
          status === "underProcess" ? "accept" : "reject"
        } this consultation?`,
        action: () => {
          if (!loadingScreen.isLoading) {
            setLoadingScreen((prevState) => ({
              ...prevState,
              isLoading: true,
            }));
          }
          updateConsultation(id, {
            status: status,
          })
            .then((res) => {
              if (back) navigate(`/admin/consultation/${id}`);
              setLoadingScreen({
                isLoading: false,
                message: "",
              });
              setModal({
                display: false,
                message: "",
                action: null,
              });
            })
            .catch((e) => {
              console.log(e);
              setLoadingScreen({
                isLoading: false,
                message: "",
              });
              setModal({
                display: false,
                message: "",
                action: null,
              });
            });
        },
      });
    } else {
      // basically the same
      if (!loadingScreen.isLoading) {
        setLoadingScreen((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
      }
      updateConsultation(id, {
        status: status,
      })
        .then((res) => {
          setLoadingScreen({
            isLoading: false,
            message: "",
          });
        })
        .catch((e) => {
          console.log(e);
          setLoadingScreen({
            isLoading: false,
            message: "",
          });
        });
    }
  }

  function agreeConsultationLOE() {
    if (!loadingScreen.isLoading) {
      setLoadingScreen((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
    }
    updateConsultation(id, {
      loe: true,
    })
      .then((res) => {
        setLetterOfEngagement({
          accepted: true,
          isModalOpen: false,
        });
        setLoadingScreen({
          isLoading: false,
          message: "",
        });
      })
      .catch((e) => {
        console.log(e);
        setLoadingScreen({
          isLoading: false,
          message: "",
        });
      });
  }

  function getData() {
    if (!loadingScreen.isLoading) {
      setLoadingScreen((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
    }
    getConsultations()
      .then((res) => {
        const findConsultation = res.data.data.find(
          (consultation) => consultation.id.toString() === id.toString()
        );
        setLetterOfEngagement((prevState) => ({
          ...prevState,
          accepted: findConsultation.loe,
        }));
        setUploadedFiles(findConsultation.submission_files);
        setStatus(findConsultation.status);
        setTaxYears(
          findConsultation.submission_answers.find(
            (question) => question.question_code === "taxYears"
          ).answer.value
        );

        setLoadingScreen({
          isLoading: false,
          message: "",
        });
      })
      .catch((e) => {
        console.log(e);
        setLoadingScreen({
          isLoading: false,
          message: "",
        });
      });
  }

  function uploadFiles() {
    setLoadingScreen({
      isLoading: true,
      message: "Uploading Files. This may take a while",
    });

    const formDataFiles = new FormData();
    newFiles.forEach((file) => {
      formDataFiles.append("files", file);
    });

    upload(id, formDataFiles)
      .then((res) => {
        setNewFiles([]);
        getData();
        updateConsultationData(id, "receivedUpload");
        setLoadingScreen({
          isLoading: false,
          message: "",
        });
      })
      .catch((e) => {
        console.log(e);
        setLoadingScreen({
          isLoading: false,
          message: "",
        });
      });
  }

  useEffect(() => {
    if (userType === "admin") {
      setLetterOfEngagement((prevState) => ({
        ...prevState,
        display: false,
      }));
    }

    getData();

    getMe()
      .then((res) => setUser(res.data.data))
      .catch((e) => console.log(e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cardPreview = (file) => {
    const type =
      file.type ||
      file.filename.split(".")[file.filename.split(".").length - 1];

    if (type.includes("image")) {
      return (
        <Card.Img
          style={{ maxHeight: "100%", objectFit: "cover" }}
          variant="top"
          src={URL.createObjectURL(file)}
        />
      );
    } else if (type === "png" || type === "jpg" || type === "jpeg") {
      return (
        <Card.Img
          style={{ maxHeight: "100%", objectFit: "cover" }}
          variant="top"
          src={file.url}
        />
      );
    } else if (type.includes("pdf")) {
      return (
        <MdIcons.MdOutlinePictureAsPdf
          fontSize={"5em"}
          color="primary"
          style={{ fill: "inherit" }}
        />
      );
    } else if (type.includes("wordprocessingml") || type.includes("msword")) {
      return (
        <SiIcons.SiMicrosoftword
          fontSize={"5em"}
          color="primary"
          style={{ fill: "inherit" }}
        />
      );
    } else if (type.includes("spreadsheetml")) {
      return (
        <SiIcons.SiMicrosoftexcel
          fontSize={"5em"}
          color="primary"
          style={{ fill: "inherit" }}
        />
      );
    } else if (type === "jpg") {
      return (
        <Card.Img
          style={{ maxHeight: "100%", objectFit: "cover" }}
          variant="top"
          src={file.url}
        />
      );
    }
  };

  const filePreview = () => {
    if (displayFileModal.isOpen) {
      const type =
        displayFileModal.file.type ||
        displayFileModal.file.filename.split(".")[
          displayFileModal.file.filename.split(".").length - 1
        ];

      if (type.includes("image")) {
        return (
          <div className="d-flex justify-content-center h-100">
            <Image
              fluid
              className="h-100 mx-auto"
              src={URL.createObjectURL(displayFileModal.file)}
            />
          </div>
        );
      } else if (type.includes("pdf")) {
        return (
          <embed
            src={
              displayFileModal.file.type
                ? URL.createObjectURL(displayFileModal.file)
                : displayFileModal.file.url
            }
            type="application/pdf"
            frameBorder="0"
            scrolling="auto"
            height="100%"
            width="100%"
          ></embed>
        );
      } else if (type === "jpg") {
        return (
          <div className="d-flex justify-content-center h-100">
            <Image
              fluid
              className="h-100 mx-auto"
              src={displayFileModal.file.url}
              style={{ objectFit: "contain" }}
            />
          </div>
        );
      }
    }
  };

  const card = (file, type) => {
    const cardButton = () => {
      if (type === "new") {
        return (
          <Button
            className="rounded px-2 py-1"
            variant="danger"
            size="sm"
            style={{ fontSize: "0.9em" }}
            onClick={() =>
              setModal({
                display: true,
                message: "Are you sure you want to delete this file?",
                action: () => {
                  deleteFile(file.name);
                  setModal({
                    display: false,
                    message: "",
                    action: null,
                  });
                },
              })
            }
          >
            Delete
          </Button>
        );
      } else if (type === "uploaded") {
        return (
          <Button
            className="rounded px-2 py-1"
            variant="light"
            size="sm"
            style={{ fontSize: "0.9em" }}
            type="submit"
            onClick={() => downloadFile(file.download_url)}
          >
            Download
          </Button>
        );
      }
    };

    return (
      <Card className="border border-primary">
        <div
          className="border  d-flex justify-content-center align-items-center overflow-hidden"
          style={{ aspectRatio: 5 / 4 }}
          onClick={() =>
            setDisplayFileModal({
              isOpen: true,
              file: file,
            })
          }
        >
          {cardPreview(file)}
        </div>
        <Card.Body>
          <Card.Title className="m-0">{file.name || file.filename}</Card.Title>
        </Card.Body>
        <div className="d-flex gap-2 position-absolute end-0 top-0 m-3 ">
          {cardButton()}
        </div>
      </Card>
    );
  };

  return (
    <>
      <Modal
        show={displayFileModal.isOpen}
        fullscreen
        onHide={() =>
          setDisplayFileModal({
            isOpen: false,
            file: null,
          })
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {displayFileModal.file?.name || displayFileModal.file?.filename}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{filePreview()}</Modal.Body>
      </Modal>

      {/* 
      
       */}
      <Modal
        show={!letterOfEngagement.accepted && letterOfEngagement.display}
        size="xl"
        backdrop="static"
        onHide={() => navigate(-1)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Letter of Engagement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* {AppConstants().letterOfEngagement} */}
          <h3 className="m-0 bold text-center">
            <span className="primary">Income</span>
            <span> </span>
            <span style={{ color: "#BB892D" }}>Tax Assistant</span>
          </h3>
          <p className="m-0 bold text-center">Seminole, Florida</p>
          <br />
          <p className="m-0">{moment(new Date()).format("MMMM DD, YYYY")}</p>
          <br />
          <p className="m-0 bold">
            {user.firstname} {user.lastname}
          </p>
          <p className="m-0">{user.address}</p>
          <p className="m-0">
            {user.city}, {user.county}, {user.state}, {user.zip}
          </p>
          <br />
          <p className="m-0">Dear {user.firstname}:</p>
          <br />
          <p className="m-0">
            This letter is to confirm and specify the term of our engagement
            with you and to clarify the nature and extent of the services we
            will provide. In order to ensure an understanding of our mutual
            responsibilities, we ask all clients for whom returns are prepared
            to confirm the following arrangements.
          </p>
          <br />
          <p className="m-0">
            We will prepare your {taxYears} federal and state personal or
            corporate tax returns from the information which you will furnish to
            us. We will not audit or otherwise verify the data you submit,
            although it may be necessary to ask you for clarification of some of
            the information.
          </p>
          <br />
          <p className="m-0">
            It is your responsibility to provide all the information required
            for the preparation of complete and accurate returns. You should
            retain all the documents, cancelled checks and other data that form
            the basis of these returns. These may be necessary to prove the
            accuracy and completeness of the returns to a taxing authority. You
            have the final responsibility for the income tax returns and,
            therefore, you should review them carefully before you sign them.
          </p>
          <br />
          <p className="m-0">
            Our work in connection with the preparation of your income tax
            returns does not include any procedures designed to discover
            defalcations and/or other irregularities, should and exist. We will
            render such accounting and bookkeeping assistance as determined to
            be necessary for preparation of the income tax returns.
          </p>
          <br />
          <p className="m-0">
            The law provides various penalties that may be imposed when
            taxpayers understate their tax liability. If you would like
            information on the amount or the circumstances of these penalties,
            please contact us.
          </p>
          <br />
          <p className="m-0">
            Your returns may be selected for review by the taxing authorities.
            Any proposed adjustments by the examining agent are subject to
            certain rights of appeal. In the event of such government tax
            examination, we will be available upon request to represent you and
            will render additional invoices for the time and expenses incurred.
          </p>
          <br />
          <p className="m-0">
            Our fee for these services will be based upon the amount of time
            required at standard billing rates plus out­of­pocket expenses. All
            invoices are due and payable upon presentation.
          </p>
          <br />
          <p className="m-0">
            If the foregoing fairly sets forth your understanding, please sign
            the enclosed copy of this letter in the space indicated and return
            it to our office. However, if there are other tax returns you expect
            us to prepare, please inform us by noting so at the end or the
            return copy of this letter.
          </p>
          <br />
          <p className="m-0">
            We want to express our appreciation for this opportunity to work
            with you.
          </p>
          <br />
          <p className="m-0">Very truly yours,</p>
          <br />
          <p className="m-0 bold">Income Tax Assistant</p>

          <div className="d-flex justify-content-center mt-5">
            <Button className="rounded" onClick={() => agreeConsultationLOE()}>
              I Acknowledge
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <RejectionModal
        isOpen={rejectionModal.isOpen}
        close={() => setRejectionModal({ isOpen: false })}
        handleReject={() => updateConsultationData(id, "rejected", true)}
        status={status}
      />

      <Container>
        <Header title={"Consultation Files"} />
        {userType === "client" ? (
          <Row className="sticky-top bg-white mb-3">
            <Col className="bg-light m-3 rounded p-0 border border-primary position-relative">
              <Form.Group
                controlId="formFile"
                className="m-3 rounded"
                style={{ borderStyle: "dashed", borderColor: "inherit" }}
              >
                <Form.Control
                  ref={uploadRef}
                  type="file"
                  style={{ height: "100px", opacity: 0 }}
                  onChange={(e) => {
                    setNewFiles([...newFiles, ...e.target.files]);
                  }}
                  multiple
                  accept=".jpeg, .docx, .doc, .pdf"
                />
              </Form.Group>
              <div className="position-absolute top-0 left-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center pe-none">
                <FiIcons.FiUpload className="me-2" fontSize={"1.5em"} />
                <p className="m-0">Click or drag and drop your files here</p>
              </div>
            </Col>
          </Row>
        ) : null}
        {newFiles.length ? (
          <>
            <h3 className="primary">To be uploaded</h3>
            <hr />
            <Row className="g-3">
              {newFiles.map((file, index) => {
                return (
                  <Col sm={6} md={4} key={index} style={{ cursor: "pointer" }}>
                    {card(file, "new")}
                  </Col>
                );
              })}
            </Row>
          </>
        ) : null}
        {uploadedFiles ? (
          <>
            <h3 className="primary mt-5">Uploaded files</h3>
            <hr />
            <Row className="g-3">
              {uploadedFiles.map((file, index) => {
                console.log(uploadedFiles);
                return (
                  <Col sm={6} md={4} key={index} style={{ cursor: "pointer" }}>
                    {card(file, "uploaded")}
                  </Col>
                );
              })}
            </Row>
          </>
        ) : null}
        <Row className=" sticky-bottom">
          <Col className="d-flex justify-content-center gap-3 my-3">
            {userType === "client" ? (
              <>
                <Button
                  disabled={!newFiles.length}
                  onClick={uploadFiles}
                  className="rounded"
                >
                  Upload
                </Button>
                {uploadedFiles?.length ? (
                  <Button
                    variant="light"
                    className="rounded"
                    onClick={downloadFiles}
                  >
                    Download Uploaded
                  </Button>
                ) : null}
                {status === "rejected" ? (
                  <Button
                    variant="danger"
                    className="rounded"
                    onClick={() => setRejectionModal({ isOpen: true })}
                  >
                    Additional Information
                  </Button>
                ) : null}
              </>
            ) : (
              <>
                {status === "receivedUpload" ? (
                  <Button
                    className="rounded"
                    onClick={() => {
                      updateConsultationData(id, "underProcess", true);
                    }}
                  >
                    Accept
                  </Button>
                ) : null}
                <Button
                  className="rounded"
                  variant="danger"
                  onClick={() =>
                    setRejectionModal({
                      isOpen: true,
                    })
                  }
                >
                  {userType === "client"
                    ? "View Additional Information"
                    : status === "underProcess"
                    ? "View Additional Information"
                    : "Ask Additional Information"}
                </Button>
                <Button
                  variant="light"
                  className="rounded"
                  onClick={downloadFiles}
                >
                  Download All
                </Button>
              </>
            )}
          </Col>{" "}
          :
        </Row>
      </Container>
    </>
  );
}

export default ConsultationFiles;
