import { DatatableWrapper, TableBody, TableHeader } from "react-bs-datatable";
import { Table } from "react-bootstrap";

export const ConsultationCalls = () => {
  const consultationCallsHeader = [
    {
      title: "Called At",
      prop: "createdAt",
      isSortable: true,
    },
    {
      title: "Duration",
      prop: "duration",
      isSortable: true,
    },
  ];

  const sampleConsultationCallsBody = [
    {
      createdAt: "12/03/23 10:34 AM",
      duration: "00:12:36",
    },
    {
      createdAt: "12/04/23 12:12 AM",
      duration: "00:08:04",
    },
  ];

  return (
    <div className="consultation-calls">
      <DatatableWrapper
        body={sampleConsultationCallsBody}
        headers={consultationCallsHeader}
      >
        <Table>
          <TableHeader />
          <TableBody />
        </Table>
      </DatatableWrapper>
    </div>
  );
};
