import React from "react";

export const Header = ({ title }) => {
  return (
    <div className="mt-3">
      <h1 className="primary">{title}</h1>
      <hr />
    </div>
  );
};

export default Header;
