import React, { useContext, useEffect, useState } from "react";

import { Alert, Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Button, Header } from "../../components";
import { UtilitiesContext } from "../../context/UtilitiesContext";

import { getConsultations } from "../../service/consultationService";

function ClientDashboard() {
  const { setLoadingScreen } = useContext(UtilitiesContext);

  const [hasSubmittedConsultation, setHasSubmittedConsultation] =
    useState(false);

  useEffect(() => {
    setLoadingScreen({
      isLoading: true,
    });
    getConsultations()
      .then((res) => {
        setHasSubmittedConsultation(!res.data.data.length);
        setLoadingScreen({
          isLoading: false,
        });
      })
      .catch((e) => {
        console.log(e);
      });
    //eslint-disable-next-line
  }, []);

  return (
    <Container>
      <Header title={"Dashboard"} />

      {hasSubmittedConsultation ? (
        <Link to="/consultation/request/free">
          <Button buttonStyle="btn--primary">Request Free Consultation</Button>
        </Link>
      ) : null}
      <Row className="mt-3">
        <Col xs={12} md={6} xl={4}>
          <Card>
            <Card.Body>
              <Alert
                className="d-flex align-items-center justify-content-center m-0"
                variant="success"
              >
                <h1 style={{ fontSize: "5em" }}>20</h1>
              </Alert>
            </Card.Body>
            <Card.Body>
              <Card.Title className="text-center">Orders</Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default ClientDashboard;
