import React from "react";
import "../App.css";
import "../styles/LandingPage.css";
import { Button } from "../components";
import { Container, Row, Col } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import Moment from "moment-timezone";
import { Link } from "react-router-dom";

function LandingPage() {
  return (
    <>
      {!isMobile ? (
        <Container>
          <Row>
            <Col sm={12} lg={6} style={{ marginTop: 100 }}>
              <h1 className="h1--primary">
                The <b>TAX</b> advice you deserve <br />
                bound with experience <br />
                and trust.
              </h1>

              <h3 className="h3--header-subtext justify">
                Experienced tax professionals and CPAs at your disposal for all
                your tax needs. Personal returns starting at <b>$150.</b>
              </h3>
              <br />
              <br />
              <Link to="/register">
                <Button buttonStyle="btn--primary btn--large">
                  Click here to get started
                </Button>
              </Link>
            </Col>
            <Col sm={12} lg={6}>
              <center>
                <img
                  src="/images/income-tax-assistant-banner-tax-advise-photo.jpg"
                  alt="income-tax-assistant-banner-tax-advice"
                  className="img-fluid"
                />
              </center>
            </Col>
          </Row>
        </Container>
      ) : (
        <div className="mobile-header-container">
          <center>
            <h1 className="h1--primary-mobile">
              The <b>TAX</b> advice you deserve bound with experience and trust.
            </h1>
          </center>
          <Container>
            <center>
              <br />
              <h3 className="h3--header-subtext-mobile">
                <i> Tax Filings Starting @ $150.00 for Individuals.</i>
                <br />
                <br />
                Our Tax and Financial experts provide you with unparalleled
                answers to your most sensitive situation in life to simplify
                your tax situation.
                {/* Whether you are filing
                  an individual 1040, Joint (MFJ), Corporate(1120), or partnership
                  (1065) or if you experienced other complex situations (Estate),
                  all of our professionals will provide you with the most efficient
                  direction review process, and file your Tax returns
                  immediately. */}
              </h3>
              <br />
              <Link to="/register">
                <Button buttonStyle="btn--primary btn--large">
                  Register Now
                </Button>
              </Link>
            </center>
          </Container>
        </div>
      )}
      <br />
      <br />
      <br />
      <Container className="tax-filing-steps-container">
        <center>
          <h2 className="h2--primary">We simplify your tax filings</h2>
          <p>
            Our team is prepared to cover your most complex tax filings.
            <br />
            <br />
            <b>Tax filings made simple as 1,2,3.</b>
          </p>
        </center>

        <Row>
          <Col sm={12} md={6} lg={4} style={{ marginTop: 10 }}>
            <div className="tax-filing-step-item-primary">
              <h4 className="tax-filing-step-title-primary">Step 1:</h4>
              <p>
                <b>Sign up</b> for your account
              </p>
            </div>
          </Col>
          <Col sm={12} md={6} lg={4} style={{ marginTop: 10 }}>
            <div className="tax-filing-step-item-white">
              <h4 className="tax-filing-step-title-white">Step 2:</h4>
              <p>
                Complete your <b>tax profile</b>
              </p>
            </div>
          </Col>
          <Col sm={12} md={6} lg={4} style={{ marginTop: 10 }}>
            <div className="tax-filing-step-item-primary">
              <h4 className="tax-filing-step-title-primary">Step 3:</h4>
              <p>
                <b>Securely</b> upload your tax documents
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <Container>
        <Row>
          <Col sm={12} lg={6} style={{ marginTop: 20 }}>
            <div
              className="tax-filings-service-item-white"
              style={{ paddingBottom: 54 }}
            >
              <center>
                <i className="fas fa-file-lines"></i>
              </center>
              <h3 className="tax-filing-service-title-white">
                Individual Tax Preparation
              </h3>
              <ul>
                <li>Tax Return Preparation and Planning (Federal & State)</li>
                <li>Quarterly Estimates and Withholding Recommendations</li>
                <li>Required Minimum Distribution Calculations</li>
              </ul>
            </div>
          </Col>
          <Col sm={12} lg={6} style={{ marginTop: 20 }}>
            <div className="tax-filings-service-item-white">
              <center>
                <i className="fas fa-file-lines"></i>
              </center>
              <h3 className="tax-filing-service-title-white">
                Business Tax Preparation
              </h3>
              <ul>
                <li>
                  S-Corp, LLC, Sole Proprietorship, Partnership, C-Corp Tax
                  Preparation and Planning (Federal & State)
                </li>
                <li>Partnership Allocations</li>
                <li>Entity Type Recommendations, Setup and Maintenance</li>
                <li>Audit Assistance (Federal & State)</li>
              </ul>
            </div>
          </Col>
          <Col sm={12} lg={6} style={{ marginTop: 20 }}>
            <div
              className="tax-filings-service-item-white"
              style={{ paddingBottom: 54 }}
            >
              <center>
                <i className="fas fa-file-lines"></i>
              </center>
              <h3 className="tax-filing-service-title-white">Bookkeeping</h3>
              <ul>
                <li>Weekly, monthly, quartery, semi-annualy, and annualy</li>
                <li>Federal and State payroll tax (941, 940, 1098s)</li>
                <li>State payroll taxes (employment and re-employment)</li>
              </ul>
            </div>
          </Col>
          <Col sm={12} lg={6} style={{ marginTop: 20 }}>
            <div
              className="tax-filings-service-item-white"
              style={{ paddingBottom: 54 }}
            >
              <center>
                <i className="fas fa-file-lines"></i>
              </center>
              <h3 className="tax-filing-service-title-white">Trust Tax</h3>
              <ul>
                <li>Tax Return Preparation and Planning (Federal & State)</li>
                <li>Trust Accounting</li>
                <li>Calculations of Trustee Fee</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>

      <Container style={{ marginTop: 100 }}>
        <Row>
          <Col sm={12} lg={6}>
            <img
              src="/images/serving-our-community-incom-tax-assistant.png"
              alt="serving-our-community-incom-tax-assistant"
              className="img-fluid container-rounded"
            />
          </Col>
          <Col sm={12} lg={6} style={{ marginTop: isMobile ? 50 : 0 }}>
            <h2 className={`h2--primary ${isMobile ? "center" : "right"}`}>
              Serving <br />
              Our Community
            </h2>

            <p className="justify">
              Our family-owned accounting ,tax, insurance, and financial
              services has been serving the community & country for the last 45
              years. Our team has grown and includes experienced CPAs, Financial
              Professionals, Asset Managers, Wealth Planning & dedicated support
              to provide you personal and professional financial services.
            </p>
          </Col>
        </Row>
      </Container>

      <Container className="tax-filing-service-offer">
        <center>
          <h2 className="h2-tertiary">Making your tax life simple.</h2>

          <p>
            Reduce your tax liability. Increase your refund. Make it simple.
          </p>

          <Link to="/register">
            <Button buttonStyle="btn--primary btn--large">Register Now</Button>
          </Link>
        </center>
      </Container>

      <div className="footer-container">
        <Container>
          <Row>
            <Col md={12} lg={4}>
              {isMobile ? (
                <center>
                  <img
                    src="/images/income-tax-assistant-logo-no-background.png"
                    alt="income-tax-assistant-navbar-logo"
                  />
                </center>
              ) : (
                <img
                  src="/images/income-tax-assistant-logo-no-background.png"
                  alt="income-tax-assistant-navbar-logo"
                />
              )}
            </Col>

            <Col md={12} lg={4} className={isMobile ? "footer-column" : ""}>
              {/* <h5 className="primary bold">Quick Links</h5>
              <p>Services</p>
              <p>Tax Forms</p>
              <p>FAQs</p> */}
            </Col>

            <Col md={12} lg={4} className={isMobile ? "footer-column" : ""}>
              <h5 className="primary bold">Contact Us</h5>
              <p>+727-642-5026</p>
              <p>
                13144 Park Boulevard North, Seminole, Florida 33776, United
                States
              </p>
            </Col>
          </Row>
        </Container>
      </div>

      <div className={`secondary-footer ${isMobile ? "mobile" : ""}`}>
        <p className="text-white center">
          Copyright © {Moment(new Date()).tz("America/New_York").format("YYYY")}{" "}
          Income Tax Assistant Services, Business Tax Consultants, Investment,
          Insurance, 401k, TSP, IRA Rollovers - <br />
          <b>All Rights Reserved.</b>
        </p>
      </div>
    </>
  );
}

export default LandingPage;
