export const config = (token, files) => {
  if (files) {
    return {
      headers: {
        "Content-Type": `multipart/form-data; boundary=${files._boundary}`,
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      data: {},
    };
  }
  return {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    data: {},
  };
};
