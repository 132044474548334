import { useState } from "react";

import questions from "../../data/consultationQuestions.json";
import useSession from "../../hooks/useSession";

import { submitForm } from "../../service/consultationService";

export const useRequestConsultation = () => {
  const [formSubmit, setFormSubmit] = useState(false);
  const [displayMsg, setDisplayMsg] = useState(null);
  const [showLoading, setShowLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");

  const { url } = useSession();

  const [requestConsultationFormData, setRequestConsultationFormData] =
    useState({});

  const handleRequestConsultationFormData = (e) => {
    setRequestConsultationFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  function handleValidation() {
    //refactor
    setFormSubmit(true);
    setShowLoading(true);
    setLoadingMsg("Validating information. Please wait.");

    if (!requestConsultationFormData["moveLastTaxYr"]) {
      document
        .querySelector(".main-inner")
        .scrollTo({ top: 0, behavior: "smooth" });
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "Please provide your answer to Question 1",
      });
    }
    if (!requestConsultationFormData["fileTaxLastYr"]) {
      document
        .querySelector(".main-inner")
        .scrollTo({ top: 0, behavior: "smooth" });
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "Please provide your answer to Question 2",
      });
    }
    if (!requestConsultationFormData["incomeFromEmployers"]) {
      document
        .querySelector(".main-inner")
        .scrollTo({ top: 0, behavior: "smooth" });
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "Please provide your answer to Question 3",
      });
    }
    if (!requestConsultationFormData["ownBusiness"]) {
      document
        .querySelector(".main-inner")
        .scrollTo({ top: 0, behavior: "smooth" });
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "Please provide your answer to Question 4",
      });
    }
    if (!requestConsultationFormData["anyDependents"]) {
      document
        .querySelector(".main-inner")
        .scrollTo({ top: 0, behavior: "smooth" });
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "Please provide your answer to Question 5",
      });
    } else {
      if (
        requestConsultationFormData["anyDependents"] === "Yes" &&
        (!requestConsultationFormData["numOfDependents"] ||
          requestConsultationFormData["numOfDependents"] === "0" ||
          requestConsultationFormData["numOfDependents"] < 1)
      ) {
        document
          .querySelector(".main-inner")
          .scrollTo({ top: 0, behavior: "smooth" });
        setShowLoading(false);
        return setDisplayMsg({
          type: "error",
          message: "Please provide the number of your dependents",
        });
      }
    }

    if (!requestConsultationFormData["received1099"]) {
      document
        .querySelector(".main-inner")
        .scrollTo({ top: 0, behavior: "smooth" });
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "Please provide your answer to Question 6",
      });
    }
    if (!requestConsultationFormData["ownOrRent"]) {
      document
        .querySelector(".main-inner")
        .scrollTo({ top: 0, behavior: "smooth" });
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "Please provide your answer to Question 7",
      });
    }
    if (!requestConsultationFormData["stocks"]) {
      document
        .querySelector(".main-inner")
        .scrollTo({ top: 0, behavior: "smooth" });
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "Please provide your answer to Question 8",
      });
    }
    if (!requestConsultationFormData["buySellProperties"]) {
      document
        .querySelector(".main-inner")
        .scrollTo({ top: 0, behavior: "smooth" });
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "Please provide your answer to Question 9",
      });
    }
    if (
      !requestConsultationFormData["approxHouseholdIncome"] ||
      requestConsultationFormData["approxHouseholdIncome"] < 1
    ) {
      document
        .querySelector(".main-inner")
        .scrollTo({ top: 0, behavior: "smooth" });
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "Please provide a valid answer to Question 10",
      });
    }
    if (!requestConsultationFormData["retirementIncome"]) {
      document
        .querySelector(".main-inner")
        .scrollTo({ top: 0, behavior: "smooth" });
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "Please provide your answer to Question 11",
      });
    }
    if (!requestConsultationFormData["priorTaxCopy"]) {
      document
        .querySelector(".main-inner")
        .scrollTo({ top: 0, behavior: "smooth" });
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "Please provide your answer to Question 12",
      });
    }

    if (
      !requestConsultationFormData["contactNumber"] ||
      requestConsultationFormData["contactNumber"].length < 10 ||
      requestConsultationFormData["contactNumber"].length > 10
    ) {
      document
        .querySelector(".main-inner")
        .scrollTo({ top: 0, behavior: "smooth" });
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message:
          "Please provide a valid best number to contact you. Please note that contact numbers should not be lower or exceed 10 digits.",
      });
    }

    if (!requestConsultationFormData["isAlwaysAvailable"]) {
      document
        .querySelector(".main-inner")
        .scrollTo({ top: 0, behavior: "smooth" });
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "Please answer if you are always available.",
      });
    } else {
      if (requestConsultationFormData["isAlwaysAvailable"] === "No") {
        if (!requestConsultationFormData["availableDay"]) {
          document
            .querySelector(".main-inner")
            .scrollTo({ top: 0, behavior: "smooth" });
          setShowLoading(false);
          return setDisplayMsg({
            type: "error",
            message:
              "Please select the best day of the week that you are available.",
          });
        }

        if (!requestConsultationFormData["availableTime"]) {
          document
            .querySelector(".main-inner")
            .scrollTo({ top: 0, behavior: "smooth" });
          setShowLoading(false);
          return setDisplayMsg({
            type: "error",
            message: "Please select the best time to contact you.",
          });
        }
      }
    }
    const regex = new RegExp("^[0-9]*([,][0-9\\s]+)*$");
    if (
      !requestConsultationFormData["taxYears"] ||
      !regex.test(requestConsultationFormData["taxYears"])
    ) {
      document
        .querySelector(".main-inner")
        .scrollTo({ top: 0, behavior: "smooth" });
      setShowLoading(false);
      return setDisplayMsg({
        type: "error",
        message: "Please provide a valid value to Question 14.",
      });
    }

    if ( displayMsg !== null && displayMsg.type === "error") {
      document
        .querySelector(".main-inner")
        .scrollTo({ top: 0, behavior: "smooth" });
    }

    setShowLoading(false);
    setLoadingMsg("");
    handleSubmit();
  }

  function handleSubmit() {
    setShowLoading(true);
    setLoadingMsg("Your Consulation Form is being submitted");

    submitForm({
      answers: Object.keys(requestConsultationFormData).reduce(
        (answers, question) => {
          answers.push({
            question_code: question,
            question: questions.find(
              (findQuestion) => findQuestion.name === question
            ).question,
            answer: {
              value: requestConsultationFormData[question],
            },
          });
          return answers;
        },
        []
      ),
    })
      .then((res) => {
        setLoadingMsg("Your Consulation Form has been submitted");
        setTimeout(() => {
          setShowLoading(false);
          window.location.replace(url.baseUrl + "consultation");
        }, 1500);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  return {
    handleSubmit,
    handleValidation,
    requestConsultationFormData,
    handleRequestConsultationFormData,
    formSubmit,
    displayMsg,
    showLoading,
    setShowLoading,
    loadingMsg,
  };
};
