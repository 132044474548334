import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Header } from "../../components";

import { QuickQuoteQuestionnaire, QuickQuoteBreakdown } from "./components";

function QuickQuote() {
  const [serviceType, setServiceType] = useState({});
  const [quickQuoteFormData, setQuickQuoteFormData] = useState({});
  const [showBreakdown, setShowBreakdown] = useState(false);

  function reset() {
    setShowBreakdown(false);
    setServiceType({});
    setQuickQuoteFormData({});
  }

  return (
    <Container className="mb-3">
      <Header title={"Quick Quote"} />
      {!showBreakdown ? (
        <QuickQuoteQuestionnaire
          formData={quickQuoteFormData}
          setFormData={setQuickQuoteFormData}
          serviceType={serviceType}
          setServiceType={setServiceType}
          calculate={() => setShowBreakdown(true)}
        />
      ) : (
        <>
          <QuickQuoteBreakdown
            serviceType={serviceType}
            formData={quickQuoteFormData}
          />
          <div className="d-flex justify-content-center mt-3">
            <Button className=" rounded" variant="success" onClick={reset}>
              Reset
            </Button>
          </div>
        </>
      )}
    </Container>
  );
}

export default QuickQuote;
