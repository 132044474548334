import axios from "axios";

import { config } from "../hooks/useAxios";

const API_URL = `${process.env.REACT_APP_DB_BASE_URL}users/`;

export const getUsers = async () => {
  const response = await axios.get(
    `${API_URL}all`,
    config(localStorage.getItem("token"))
  );

  return response;
};

export const getUser = async (id) => {
  const response = await axios.get(
    `${API_URL}${id}`,
    config(localStorage.getItem("token"))
  );

  return response;
};
