import axios from "axios";

import { config } from "../hooks/useAxios";

const API_URL = `${process.env.REACT_APP_DB_BASE_URL}consultations/`;

export const getConsultations = async () => {
  const response = await axios.get(
    `${API_URL}all`,
    config(localStorage.getItem("token"))
  );

  return response;
};

export const getConsultation = async (id) => {
  const response = await axios.get(
    `${API_URL}${id}`,
    config(localStorage.getItem("token"))
  );

  return response;
};

export const submitForm = async (data) => {
  const response = await axios.post(
    `${API_URL}submit`,
    data,
    config(localStorage.getItem("token"))
  );

  return response;
};

export const accept = async (id) => {
  const response = await axios.post(
    `${API_URL}${id}/accept`,
    {},
    config(localStorage.getItem("token"))
  );

  return response;
};

export const updateConsultation = async (id, data) => {
  const response = await axios.put(
    `${API_URL}${id}`,
    data,
    config(localStorage.getItem("token"))
  );

  return response;
};

export const upload = async (id, files) => {
  const response = await axios.post(
    `${API_URL}${id}/upload`,
    files,
    config(localStorage.getItem("token"), files)
  );

  return response;
};

export const consultationInvoice = async (id, data) => {
  const response = await axios.post(
    `${API_URL}${id}/invoice`,
    data,
    config(localStorage.getItem("token"), data)
  );

  return response;
};

export const payConsultation = async (id) => {
  const response = await axios.post(
    `${API_URL}${id}/pay`,
    {},
    config(localStorage.getItem("token"))
  );

  window.open(response.data.url, "_blank");

  return response;
};

export const submitTaxReturn = async (id, files) => {
  const response = await axios.post(
    `${API_URL}${id}/tax_returns`,
    files,
    config(localStorage.getItem("token"), files)
  );

  return response;
};
