import { useContext, useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import { Header } from "../../components";
import { UtilitiesContext } from "../../context/UtilitiesContext";
import { getUser } from "../../service/userService";

function UserInfo() {
  const { setLoadingScreen } = useContext(UtilitiesContext);
  const { id } = useParams();
  const navigate = useNavigate();

  const [data, setData] = useState({});

  useEffect(() => {
    setLoadingScreen({
      isLoading: true,
    });
    getUser(id)
      .then((res) => {
        setData(res.data.data);
        setLoadingScreen({
          isLoading: false,
        });
      })
      .catch((e) => {
        console.log(e);
        setLoadingScreen({
          isLoading: false,
        });
      });
  }, []);

  return (
    <Container>
      <Header title={"User Info"} />
      <Row>
        <Col sm={12} lg={6}>
          <input readOnly className="mt-3" type="text" value={data.firstname} />
          <label className="mt-2">First name and Middle Initial</label>
        </Col>
        <Col sm={12} lg={6}>
          <input readOnly className="mt-3" type="text" value={data.lastname} />
          <label className="mt-2">Last name</label>
        </Col>

        <Col sm={12} lg={6}>
          <input
            readOnly
            className="mt-3"
            type="number"
            value={data.phone_number}
          />
          <label className="mt-2">Phone Number</label>
        </Col>
        <Col sm={12} lg={6}>
          <input
            readOnly
            className="mt-3"
            type="number"
            value={data.tel_number}
          />
          <label className="mt-2">Telephone Number</label>
        </Col>
        <Col sm={12} lg={6}>
          <input readOnly className="mt-3" type="text" value={data.email} />
          <label className="mt-2">Email</label>
        </Col>
      </Row>

      <hr className="mt-3" />
      <Row>
        <Col lg={12}>
          <input readOnly className="mt-3" type="text" value={data.address} />
          <label className="mt-2">Address</label>
        </Col>
        <Col sm={12} lg={6}>
          <input readOnly className="mt-3" type="text" value={data.county} />
          <label className="mt-2">County</label>
        </Col>
        <Col sm={12} lg={6}>
          <input readOnly className="mt-3" type="text" value={data.city} />
          <label className="mt-2">City</label>
        </Col>
        <Col sm={12} lg={6}>
          <input readOnly className="mt-3" type="text" value={data.state} />
          <label className="mt-2">State</label>
        </Col>
        <Col sm={12} lg={6}>
          <input readOnly className="mt-3" type="number" value={data.zip} />
          <label className="mt-2">Zip/Postal Number</label>
        </Col>
        <div className="mt-50">
          <center>
            <Button
              className="rounded"
              variant="success"
              onClick={() => navigate(-1)}
            >
              Go Back
            </Button>
          </center>
        </div>
      </Row>
    </Container>
  );
}

export default UserInfo;
