import React from "react";
import { Button, Modal } from "react-bootstrap";

export const InvoiceModal = ({
  isOpen,
  close,
  invoice,
  handleReject,
  readOnly,
}) => {
  function downloadFile(url) {
    const a = document.createElement("a");
    a.href = url;
    a.download = url.split("/").pop();
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  return (
    <Modal show={isOpen} onHide={close} fullscreen>
      <Modal.Header>
        <Modal.Title>Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <embed
          src={invoice?.url}
          type="application/pdf"
          frameBorder="0"
          scrolling="auto"
          height="100%"
          width="100%"
        ></embed>
      </Modal.Body>
      <Modal.Body className="flex-grow-0">
        <div className="d-flex gap-2 mx-auto justify-content-center">
          <Button
            className="rounded"
            onClick={() => downloadFile(invoice?.download_url)}
          >
            Download Invoice
          </Button>
          <Button variant="danger" className="rounded" onClick={close}>
            Close
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
