import axios from "axios";

import { config } from "../hooks/useAxios";

const API_URL = `${process.env.REACT_APP_DB_BASE_URL}auth/`;

export const loginClient = async (data) => {
  const response = await axios.post(`${API_URL}signin`, data);

  if (response.data.status) {
    const { data: user } = response.data;

    localStorage.setItem("id", user.me.id);
    localStorage.setItem("token", user.access_token);
    localStorage.setItem("user_type", user.me.type);
  }

  return response;
};

export const loginAdmin = async (data) => {
  const response = await axios.post(`${API_URL}admin/signin`, data);

  if (response.data.status) {
    const { data: user } = response.data;

    localStorage.setItem("id", user.me.id);
    localStorage.setItem("token", user.access_token);
    localStorage.setItem("user_type", user.me.type);
  }

  return response;
};

export const register = async (data) => {
  const response = await axios.post(`${API_URL}register`, data);

  return response;
};

export const logout = async () => {
  const response = await axios.post(
    `${API_URL}logout`,
    config(localStorage.getItem("token"))
  );

  localStorage.clear();

  return response;
};

export const getMe = async () => {
  const response = await axios.post(
    `${API_URL}logout`,
    config(localStorage.getItem("token"))
  );

  return response;
};

export const forgotPassword = async (email) => {
  const response = await axios.post(
    `${API_URL}forgot-password`,
    { email: email },
    config(localStorage.getItem("token"))
  );

  return response;
};

export const changePassword = async (data) => {
  const response = await axios.post(
    `${API_URL}change-password`,
    data,
    config(localStorage.getItem("token"))
  );

  return response;
};
