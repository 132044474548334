import React from "react";
import { ConsulationCard, ConsultationTable } from "./index";

export const ConsultationList = ({ data: consultations, cards, table }) => {
  const listComponent = () => {
    if (cards) {
      return consultations.map((consultation, index) => {
        return (
          <ConsulationCard
            key={index}
            data={consultation}
            user={localStorage.getItem("user_type")}
          />
        );
      });
    } else if (table) {
      return <ConsultationTable data={consultations} />;
    } else {
      return null;
    }
  };

  return (
    <div className="free-consultations">
      <div className="free-consultation__list d-flex flex-column gap-3">
        {listComponent()}
      </div>
    </div>
  );
};
