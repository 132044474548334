import { useContext, useEffect, useState } from "react";
import { Button, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ConsultationList, Header } from "../../components";
import { UtilitiesContext } from "../../context/UtilitiesContext";
import { getConsultations } from "../../service/consultationService";

function Consultations() {
  const [consultations, setConsultations] = useState([]);

  const { setLoadingScreen } = useContext(UtilitiesContext);

  const [userType] = useState(localStorage.getItem("user_type"));

  useEffect(() => {
    setLoadingScreen({
      isLoading: true,
    });

    getConsultations()
      .then((res) => {
        setConsultations(res.data.data);
        setLoadingScreen({
          isLoading: false,
        });
      })
      .catch((e) => {
        console.log(e);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Header title={"Consultations"} />
      {userType === "client" ? (
        <Link to={`request${!consultations.length ? "/free" : ""}`}>
          <Button className="rounded mb-3 mb-md-0" variant="success">
            Request {!consultations.length ? "Free" : ""} Consultation
          </Button>
        </Link>
      ) : null}
      <ConsultationList data={consultations} table />
    </Container>
  );
}

export default Consultations;
