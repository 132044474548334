import React, { useState, useEffect } from "react";
import "../styles/Sidebar.css";

import * as IoIcons from "react-icons/io";
import * as FAIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";

import { NavLink } from "react-router-dom";
import useSession from "../hooks/useSession";
import { logout } from "../service/authService";

export const Sidebar = ({ children }) => {
  const { url, user } = useSession();

  const [isOpen, setIsOpen] = useState(true);
  const toggle = () => setIsOpen(!isOpen);

  const clientMenu = [
    /* {
      path: "/dashboard",
      name: "Dashboard",
      icon: <AiIcons.AiFillDashboard />,
    }, */
    {
      path: "/consultation",
      name: "Consulations",
      icon: <FAIcons.FaUserEdit />,
    },
    {
      path: "/quick-quote",
      name: "Quick Quote",
      icon: <BsIcons.BsFillChatLeftQuoteFill />,
    },

    {
      path: "/settings",
      name: "Settings",
      icon: <IoIcons.IoIosSettings />,
    },
  ];

  const adminMenu = [
    {
      path: "/admin/dashboard",
      name: "Dashboard",
      icon: <AiIcons.AiFillDashboard />,
    },
    {
      path: "/admin/consultation",
      name: "Consulations",
      icon: <FAIcons.FaUserEdit />,
    },
    {
      path: "/settings",
      name: "Settings",
      icon: <IoIcons.IoIosSettings />,
    },
  ];

  const handleLogout = () => {
    logout()
      .then((res) => {
        localStorage.clear();
        window.location.replace(url.baseUrl + "login");
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    console.log(window.innerWidth);
    if (window.innerWidth < 768) {
      setIsOpen(false);
    }
    // getUserInfo();
    //eslint-disable-next-line
  }, []);

  return (
    <div className="d-flex flex-grow-1">
      <div style={{ width: isOpen ? "300px" : "50px" }} className="sidebar">
        <div className="sidebar__inner">
          <div className="top_section">
            <div
              style={{ marginLeft: isOpen ? "200px" : "0px" }}
              className="bars mt-2 mb-3"
            >
              {!isOpen ? (
                <IoIcons.IoMdMenu onClick={toggle} />
              ) : (
                <IoIcons.IoMdClose onClick={toggle} />
              )}
            </div>
          </div>
          {isOpen ? (
            <center>
              <p className="text-white bold">
                {user.firstName + " " + user.lastName}
              </p>
              <hr />
            </center>
          ) : null}
          {localStorage.getItem("user_type") === "client"
            ? clientMenu.map((item, index) => (
                <NavLink
                  to={item.path}
                  key={index}
                  className="link"
                  activeclassname="active"
                >
                  <div className="icon">{item.icon}</div>
                  <div
                    style={{ display: isOpen ? "block" : "none" }}
                    className="link_text"
                  >
                    {item.name}
                  </div>
                </NavLink>
              ))
            : adminMenu.map((item, index) => (
                <NavLink
                  to={item.path}
                  key={index}
                  className="link"
                  activeclassname="active"
                >
                  <div className="icon">{item.icon}</div>
                  <div
                    style={{ display: isOpen ? "block" : "none" }}
                    className="link_text"
                  >
                    {item.name}
                  </div>
                </NavLink>
              ))}

          <div className="link logout" onClick={handleLogout}>
            <div className="icon">
              <RiIcons.RiLogoutBoxRLine />
            </div>
            <div
              style={{ display: isOpen ? "block" : "none" }}
              className="link_text"
            >
              Logout
            </div>
          </div>
        </div>
      </div>
      <main className="main position-relative w-100 ">
        <div className="main-inner position-absolute top-0 start-0 w-100 h-100 overflow-scroll">
          {children}
        </div>
      </main>
    </div>
  );
};
