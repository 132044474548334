import { useEffect, useState } from "react";
import { Alert, Card, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Header } from "../../components";
import { getConsultations } from "../../service/consultationService";
import { getUsers } from "../../service/userService";

function AdminDashboard() {
  const [clients, setClients] = useState(0);
  const [consultations, setConsultations] = useState(0);

  useEffect(() => {
    getUsers()
      .then((res) => {
        setClients(
          res.data.data.filter((user) => user.type === "client").length
        );
      })
      .catch((e) => {
        console.log(e);
      });

    getConsultations()
      .then((res) => {
        setConsultations(res.data.data.length);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <Container>
      <Header title={"Dashboard"} />
      <Row className="justify-content-center">
        <Col xs={12} md={6} xl={4}>
          <Link to="/admin/users" className="text-decoration-none">
            <Card>
              <Card.Body>
                <Alert
                  className="d-flex align-items-center justify-content-center m-0"
                  variant="success"
                >
                  <h1 style={{ fontSize: "5em" }}>{clients}</h1>
                </Alert>
              </Card.Body>
              <Card.Body>
                <Card.Title className="text-center">Clients</Card.Title>
              </Card.Body>
            </Card>
          </Link>
        </Col>
        {/* <Col xs={12} md={6} xl={4}>
          <Card>
            <Card.Body>
              <Alert
                className="d-flex align-items-center justify-content-center m-0"
                variant="success"
              >
                <h1 style={{ fontSize: "5em" }}>20</h1>
              </Alert>
            </Card.Body>
            <Card.Body>
              <Card.Title className="text-center">Tax Advisors</Card.Title>
            </Card.Body>
          </Card>
        </Col> */}
        <Col xs={12} md={6} xl={4}>
          <Card>
            <Card.Body>
              <Alert
                className="d-flex align-items-center justify-content-center m-0"
                variant="success"
              >
                <h1 style={{ fontSize: "5em" }}>{consultations}</h1>
              </Alert>
            </Card.Body>
            <Card.Body>
              <Card.Title className="text-center primary">
                Consultations
              </Card.Title>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminDashboard;
