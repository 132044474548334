import { useEffect, useState } from "react";
import { Badge, Button, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

import { accept } from "../service/consultationService";

export const ConsulationCard = ({ className, user, data }) => {
  const [isAccepted, setIsAccepted] = useState(false);

  function handleAprroveConsultation(id) {
    accept(id)
      .then((res) => {
        setIsAccepted(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const actions = () => {
    if (user === "client") {
      return (
        <>
          <Button className="rounded w-100" variant="primary">
            <Link
              className="text-white text-decoration-none"
              to={`/consultation/${data.id}`}
            >
              View Form
            </Link>
          </Button>
        </>
      );
    } else {
      return (
        <>
          {!isAccepted ? (
            <Button
              onClick={() => handleAprroveConsultation(data.id)}
              className="rounded w-100"
              variant="success"
            >
              Accept
            </Button>
          ) : null}
          <Button className="rounded w-100" variant="primary">
            <Link
              className="text-white text-decoration-none"
              to={`/admin/consultation/${data.id}`}
            >
              View Form
            </Link>
          </Button>
        </>
      );
    }
  };

  const scheduledAt = () => {
    const isAlwaysAvailable = data.submission_answers?.find((answer) => {
      return answer.question_code === "isAlwaysAvailable";
    });

    if (isAlwaysAvailable) {
      if (isAlwaysAvailable.answer.value === "Yes") {
        return "Is Always Available";
      } else {
        const availableTime = data.submission_answers?.find((answer) => {
          return answer.question_code === "availableTime";
        });
        const availableDay = data.submission_answers?.find((answer) => {
          return answer.question_code === "availableDay";
        });

        return `Scheduled at ${availableDay.answer.value} on ${availableTime.answer.value}`;
      }
    }
  };

  const title = () => {
    if (user === "client") {
      return `Submitted at ${new Date(data.created_at).toLocaleDateString()}`;
    } else {
      return `${data.user.firstname} ${data.user.lastname}`;
    }
  };

  useEffect(() => {
    setIsAccepted(data.accepted);
    // console.log(data.created_at);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className={`${className}`}>
      <Card.Body className="">
        <Row className="">
          <Col xs={12} md={8} lg={10}>
            <Card.Title>
              <div className="d-flex">
                {title()}
                <Badge
                  pill
                  className="ms-2 align-self-center"
                  bg={isAccepted ? "success" : "warning"}
                  text={isAccepted ? "" : "dark"}
                  style={{ fontSize: "0.6em" }}
                >
                  {isAccepted ? "Accepted" : "Pending"}
                </Badge>
              </div>
            </Card.Title>
            <Card.Subtitle className="mt-auto text-muted">
              {scheduledAt()}
            </Card.Subtitle>
          </Col>
          <Col className="align-self-center mt-3 mt-md-0" xs={12} md={4} lg={2}>
            <div className="d-flex gap-3 align-self-center">{actions()}</div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
