import React, { useContext, useState } from "react";
import { Alert, Button, Form, Modal } from "react-bootstrap";

import * as FiIcons from "react-icons/fi";
import { useParams } from "react-router-dom";
import { UtilitiesContext } from "../context/UtilitiesContext";
import {
  submitTaxReturn,
  updateConsultation,
} from "../service/consultationService";

export const UploadTaxReturnModal = ({ isOpen, close, data, getData }) => {
  const { setLoadingScreen } = useContext(UtilitiesContext);
  const { id: consultationId } = useParams();

  const [taxReturns, setTaxReturns] = useState([]);

  function uploadTaxReturn() {
    setLoadingScreen({
      isLoading: true,
      message: "Uploading Files. This may take a while",
    });

    const formDataFiles = new FormData();
    [...taxReturns].forEach((file) => {
      formDataFiles.append("tax_returns", file);
    });

    submitTaxReturn(consultationId, formDataFiles)
      .then((res) => {
        setLoadingScreen((prevState) => ({
          ...prevState,
          isLoading: true,
        }));
        getData();
        close();
        updateConsultation(consultationId, {
          status: "completed",
        })
          .then((res) => {
            setLoadingScreen({
              isLoading: false,
              message: "",
            });
          })
          .catch((e) => {
            console.log(e);
            setLoadingScreen({
              isLoading: false,
              message: "",
            });
          });
        setLoadingScreen({
          isLoading: false,
          message: "",
        });
      })
      .catch((e) => {
        console.log(e);
        setLoadingScreen({
          isLoading: false,
          message: "",
        });
      });
  }

  return (
    <Modal show={isOpen} onHide={close}>
      <Modal.Header closeButton>
        <Modal.Title>Tax Return</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form className="mt-3">
          <Form.Label className="">Upload Tax Return</Form.Label>
          {taxReturns.length ? (
            <Alert className="p-2">
              {[...taxReturns].map((taxReturn, index) => {
                return `${taxReturn.name}${
                  index + 1 !== taxReturns.length ? ", " : ""
                }`;
              })}
            </Alert>
          ) : null}
          <div className="bg-light rounded p-0 border border-primary position-relative">
            <Form.Group
              controlId="formFile"
              className="m-3 rounded"
              style={{ borderStyle: "dashed", borderColor: "inherit" }}
            >
              <Form.Control
                onChange={(e) => setTaxReturns(e.target.files)}
                type="file"
                style={{ height: "100px", opacity: 0 }}
                accept=".pdf"
                multiple
              />
            </Form.Group>
            <div className="position-absolute top-0 left-0 w-100 h-100 d-flex flex-column justify-content-center align-items-center pe-none">
              <FiIcons.FiUpload className="me-2" fontSize={"1.5em"} />
              <p className="m-0">Click or drag and drop your files here</p>
            </div>
          </div>

          <Button
            onClick={uploadTaxReturn}
            className="rounded mt-3 w-100"
            disabled={taxReturns.length === 0}
          >
            Upload Tax Return
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};
