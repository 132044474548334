import { useEffect, useState, useContext } from "react";
import * as React from "react";
import {
  Alert,
  Button,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { useParams } from "react-router-dom";
import { UtilitiesContext } from "../../context/UtilitiesContext";

import {
  ConsultationForm,
  Header,
  ConsultationCalls,
  RejectionModal,
  InvoiceModal,
} from "../../components";
import {
  getConsultations,
  updateConsultation,
} from "../../service/consultationService";

import * as IoIcons from "react-icons/io";
import * as CGIcons from "react-icons/cg";

import "draft-js/dist/Draft.css";
import { useConsultation } from "./useConsultation";
import { useRef } from "react";
import useStopwatch from "../../hooks/useStopwatch";
import { UnderProcessModal } from "../../components/UnderProcessModal";
import { UploadTaxReturnModal } from "../../components/UploadTaxReturnModal";

function Consultation() {
  const { setLoadingScreen, setHeader } = useContext(UtilitiesContext);

  const {
    running: consultationStopwatchStart,
    setRunning: setConsultaionStopwatchStart,
    formatted: consultationStopwatch,
    setDuration: setConsultationCallDuration,
  } = useStopwatch({
    returnTime: getConsultationCallDuration,
  });

  const { id } = useParams();

  const notesRef = useRef();
  const timeRef = useRef();

  const [userType] = useState(localStorage.getItem("user_type"));
  const [consultationData, setConsultationData] = useState({});
  const [consultationNotes, setConsultationNotes] = useState({
    display: false,
    toggleable: false,
  });
  const [consultationTime, setConsultationTime] = useState({
    show: false,
    value: null,
  });
  const [rejectionModal, setRejectionModal] = useState({
    isOpen: false,
  });
  const [underProcessingModal, setUnderProcessingModal] = useState({
    isOpen: false,
  });
  const [uploadTaxReturnModal, setUploadTaxReturnModal] = useState({
    isOpen: false,
  });
  const [invoiceModal, setInvoiceModal] = useState({
    isOpen: false,
  });

  const [consultationFormData, setConsultationFormData] = useState({});
  const [content] = useState("index"); //index, calls
  const { consultationMessage } = useConsultation({
    setConsultationTime,
    setConsultationNotes,
    consultationData,
    consultationStopwatch,
    setConsultaionStopwatchStart,
    setConsultationCallDuration,
    updateConsultationData,
    viewRejections: () => setRejectionModal({ isOpen: true }),
    openUnderProcessingModal: () => setUnderProcessingModal({ isOpen: true }),
    openUploadTaxReturnModal: () => setUploadTaxReturnModal({ isOpen: true }),
    openInvoiceModal: () => setInvoiceModal({ isOpen: true }),
  });

  function toggleNotes() {
    setConsultationNotes((prevState) => ({
      ...prevState,
      display: !prevState.display,
    }));
  }

  function getConsultationCallDuration(time) {
    setConsultationTime((prevState) => ({
      ...prevState,
      value: time,
    }));
  }

  function updateConsultationNotes() {
    setLoadingScreen({
      isLoading: true,
      message: "Updating Status",
    });
    updateConsultation(consultationData.id, {
      notes: notesRef.current?.value,
    })
      .then((res) => {
        setConsultationData(res.data.data);
        setLoadingScreen({
          isLoading: false,
          message: "",
        });
      })
      .catch((e) => {
        console.log(e);
        setLoadingScreen({
          isLoading: false,
          message: "",
        });
      });
  }

  function updateConsultationData(status) {
    setLoadingScreen({
      isLoading: true,
      message: "Updating Status",
    });
    if (status === "endConsultation") {
      const [hours, minutes, seconds] = timeRef.current.innerHTML.split(":");

      updateConsultation(consultationData.id, {
        status: status,
        notes: notesRef.current?.value || consultationData.notes || "",
        duration:
          (parseInt(hours) * 60 * 60 +
            parseInt(minutes) * 60 +
            parseInt(seconds)) *
          1000,
      })
        .then((res) => {
          setConsultationData(res.data.data);
          setLoadingScreen({
            isLoading: false,
            message: "",
          });
        })
        .catch((e) => {
          console.log(e);
          setLoadingScreen({
            isLoading: false,
            message: "",
          });
        });
    } else {
      updateConsultation(consultationData.id, {
        status: status,
        notes: notesRef.current?.value || consultationData.notes || "",
        duration: consultationData.duration || 0,
      })
        .then((res) => {
          setConsultationData(res.data.data);
          setLoadingScreen({
            isLoading: false,
            message: "",
          });
        })
        .catch((e) => {
          console.log(e);
          setLoadingScreen({
            isLoading: false,
            message: "",
          });
        });
    }
  }

  function getData() {
    getConsultations()
      .then((res) => {
        const findConsultation = res.data.data.find(
          (consultation) => consultation.id.toString() === id.toString()
        );
        setConsultationData(findConsultation);
        const allowNotes = [
          "startConsultation",
          "endConsultation",
          "allowUpload",
          "receivedUpload",
          "forAccepting",
          "rejected",
          "underProcess",
          "waitingPayment",
          "waitingTaxReturn",
          "readyTaxReturn",
          "completed",
        ];
        if (allowNotes.some((allow) => allow === findConsultation.status)) {
          setConsultationNotes({
            display: true,
            toggleable: true,
          });
        }

        if (findConsultation.status === "startConsultation") {
          updateConsultation(findConsultation.id, {
            status: "endConsultation",
            notes: findConsultation.notes,
            duration: findConsultation.duration,
          })
            .then((res) => {
              setConsultationData(res.data.data);
              setConsultaionStopwatchStart(false);
              setLoadingScreen({
                isLoading: false,
                message: "",
              });
            })
            .catch((e) => {
              console.log(e);
              setLoadingScreen({
                isLoading: false,
                message: "",
              });
            });
        } else {
          setConsultationFormData(
            findConsultation.submission_answers.reduce((answers, answer) => {
              answers[answer.question_code] = answer.answer.value;

              return answers;
            }, {})
          );
        }
        setLoadingScreen({
          isLoading: false,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  }

  useEffect(() => {
    setHeader("Consultation");
    setLoadingScreen({
      isLoading: true,
    });

    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [consultationStopwatchStart]);

  const consultationContent = () => {
    if (content === "index") {
      return (
        <ConsultationForm
          formData={consultationFormData}
          setFormData={() => {}}
          readonly
        />
      );
    } else if (content === "calls") {
      return <ConsultationCalls />;
    }
  };

  return (
    <>
      <RejectionModal
        isOpen={rejectionModal.isOpen}
        close={() => setRejectionModal({ isOpen: false })}
        readOnly
        status={consultationData.status}
      />
      <UnderProcessModal
        isOpen={underProcessingModal.isOpen}
        close={() => setUnderProcessingModal({ isOpen: false })}
        data={consultationData}
        getData={getData}
      />
      <UploadTaxReturnModal
        isOpen={uploadTaxReturnModal.isOpen}
        close={() => setUploadTaxReturnModal({ isOpen: false })}
        data={consultationData}
        getData={getData}
      />
      <InvoiceModal
        isOpen={invoiceModal.isOpen}
        close={() => setInvoiceModal({ isOpen: false })}
        invoice={consultationData.invoice}
        data={consultationData}
        getData={getData}
      />
      <Container className="h-100 d-flex flex-column px-3 px-md-5">
        <Header title={"Consultation"} />
        <Row className="position-relative " style={{ zIndex: 2 }}>
          <Col>
            <div className="d-flex flex-column flex-md-row gap-3 w-100">
              <Alert
                className="m-0 px-3 py-2 d-flex flex-grow-1 justify-content-between"
                variant={
                  consultationMessage.color
                    ? consultationMessage.color
                    : "warning"
                }
              >
                <div>{consultationMessage.content}</div>
                {consultationTime.show && userType === "admin" ? (
                  <>
                    <div className="d-flex align-items-center gap-1">
                      <IoIcons.IoMdStopwatch />
                      <span ref={timeRef}>{consultationStopwatch}</span>
                    </div>
                  </>
                ) : null}
              </Alert>
              <div className="actions d-flex gap-2">
                {consultationMessage.action}
                {/* {consultationNotes.toggleable && userType === "admin" ? (
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                      <Tooltip id="button-tooltip">
                        {consultationNotes.display ? "Hide" : "Show"} Notes
                      </Tooltip>
                    }
                  >
                    <Button onClick={toggleNotes} className="rounded ">
                      <CGIcons.CgNotes />
                    </Button>
                  </OverlayTrigger>
                ) : null} */}
              </div>
            </div>
          </Col>
        </Row>
        <Row className=" pt-3 overflow-hidden flex-column flex-lg-row flex-grow-1">
          <Col className="h-100 overflow-scroll pb-3" style={{ flex: 1 }}>
            {consultationContent()}
          </Col>
          {userType === "admin" ? (
            <>
              <Col
                className="bg-light p-3 d-none d-md-block"
                style={{ flex: 1 }}
              >
                <Form.Group
                  className="mb-3 d-flex flex-column h-100"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Consultation Notes</Form.Label>
                  <Form.Control
                    ref={notesRef}
                    as="textarea"
                    className="flex-grow-1"
                    rows={3}
                    defaultValue={consultationData.notes}
                  />
                  <div className="d-flex gap-3">
                    <Button
                      className="rounded mt-3 flex-grow-1"
                      onClick={updateConsultationNotes}
                    >
                      Save
                    </Button>
                  </div>
                </Form.Group>
              </Col>
              <Col
                className="bg-light p-3 d-md-none"
                style={
                  consultationNotes.display ? { flex: 1 } : { flex: "0 1 0" }
                }
              >
                <Form.Group
                  className="mb-3 d-flex flex-column h-100"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>Consultation Notes</Form.Label>
                  {consultationNotes.display ? (
                    <Form.Control
                      ref={notesRef}
                      as="textarea"
                      className="flex-grow-1"
                      rows={3}
                      defaultValue={consultationData.notes}
                    />
                  ) : null}
                  <div className="d-flex gap-3">
                    <Button
                      className="rounded mt-3 flex-grow-1"
                      onClick={updateConsultationNotes}
                    >
                      Save
                    </Button>
                    <Button
                      className="rounded mt-3"
                      onClick={() =>
                        setConsultationNotes((prevState) => ({
                          ...prevState,
                          display: !consultationNotes.display,
                        }))
                      }
                    >
                      {consultationNotes.display ? "Hide" : "Show"} Notes
                    </Button>
                  </div>
                </Form.Group>
              </Col>
            </>
          ) : null}
        </Row>
      </Container>
    </>
  );
}

export default Consultation;
