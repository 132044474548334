import React, { useContext, useEffect, useState } from "react";
import { Alert, Button, Col, Container, Row } from "react-bootstrap";
import Select from "react-select";

import { AppConstants } from "../../AppConstants";
import { UtilitiesContext } from "../../context/UtilitiesContext";
import useSession from "../../hooks/useSession";
import { getMe, updateMe } from "../../service/profileService";

import { Header } from "../../components";
import { changePassword } from "../../service/authService";
function Settings() {
  const { setLoadingScreen, setModal } = useContext(UtilitiesContext);

  const [displayMsg] = useState(null);
  const [formSubmit] = useState(false);
  // const [showLoading, setShowLoading] = useState(false);
  // const [loadingMsg, setLoadingMsg] = useState("");

  const { url, userType } = useSession();

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [telNumber, setTelNumber] = useState("");
  const [email, setEmail] = useState("");

  const [address, setAddress] = useState("");
  const [county, setCounty] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("Florida");
  const [zip, setZip] = useState("");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  function updateData() {
    setLoadingScreen({
      isLoading: true,
      message: "Updating Profile",
    });

    if (userType === "client") {
      updateMe({
        firstname,
        lastname,
        phone_number: phoneNumber,
        tel_number: telNumber,
        email,
        address,
        county,
        city,
        state,
        zip,
      })
        .then((res) => {
          setLoadingScreen({
            isLoading: false,
            message: "",
          });
          window.location.reload();
        })
        .catch((e) => {
          console.log(e);
          setLoadingScreen({
            isLoading: false,
            message: "",
          });
        });
    } else {
      updateMe({
        firstname,
        lastname,
        email,
      })
        .then((res) => {
          setLoadingScreen({
            isLoading: false,
            message: "",
          });
          window.location.reload();
        })
        .catch((e) => {
          console.log(e);
          setLoadingScreen({
            isLoading: false,
            message: "",
          });
        });
    }
  }

  function handleUpdatePassword() {
    if (password !== confirmPassword) {
      setModal({
        display: true,
        message: "Your password didn't match please try again",
        action: () => {
          setModal({
            display: false,
            message: "",
            action: null,
          });
        },
      });
    } else {
      setLoadingScreen({
        isLoading: true,
        message: "Updating your Password",
      });
      updateMe({
        password,
      })
        .then((res) => {
          setLoadingScreen({
            isLoading: false,
            message: "",
          });
          setModal({
            display: true,
            message: "Password successfully updated",
            action: () => {
              setModal({
                display: false,
                message: "",
                action: null,
              });
            },
          });
        })
        .catch((e) => {
          console.log(e);
          setLoadingScreen({
            isLoading: false,
            message: "",
          });
        });
    }
  }

  useEffect(() => {
    setLoadingScreen({
      isLoading: true,
    });

    if (userType) {
      getMe()
        .then((res) => {
          const info = res.data.data;

          setFirstname(info.firstname);
          setLastname(info.lastname);
          setPhoneNumber(info.phone_number);
          setTelNumber(info.tel_number);
          setEmail(info.email);
          setAddress(info.address);
          setCounty(info.county);
          setCity(info.city);
          setState(info.state);
          setZip(info.zip);

          setLoadingScreen({
            isLoading: false,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userType]);

  return (
    <Container className="mb-3">
      <Header title={"Settings"} />
      <h2 className="primary mt-5">Update Info</h2>
      <hr />
      {displayMsg !== null ? (
        <>
          <Alert variant={displayMsg.type === "error" ? "danger" : "success"}>
            {displayMsg.message}
          </Alert>
          {displayMsg.type === "success" ? (
            <center>
              <Button
                buttonStyle="btn--primary"
                onClick={() => window.location.replace(url.baseUrl + "login")}
              >
                Login
              </Button>
            </center>
          ) : null}
        </>
      ) : null}
      <Row>
        <Col sm={12} lg={6}>
          <input
            type="text"
            className={`mt-20 ${
              formSubmit && (firstname === "" || firstname === null)
                ? "is-invalid"
                : ""
            }`}
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
          />
          <label>
            First name and Middle Initial<span className="danger">*</span>
          </label>
        </Col>
        <Col sm={12} lg={6}>
          <input
            type="text"
            className={`mt-20 ${
              formSubmit && (lastname === "" || lastname === null)
                ? "is-invalid"
                : ""
            }`}
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
          />
          <label>
            Last name<span className="danger">*</span>
          </label>
        </Col>

        {userType === "client" ? (
          <>
            <Col sm={12} lg={6}>
              <input
                type="number"
                className={`mt-20 ${
                  formSubmit && (phoneNumber === "" || phoneNumber === null)
                    ? "is-invalid"
                    : ""
                }`}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <label>
                Phone Number<span className="danger">*</span>
              </label>
            </Col>
            <Col sm={12} lg={6}>
              <input
                type="number"
                className={`mt-20 ${
                  formSubmit && (telNumber === "" || telNumber === null)
                    ? "is-invalid"
                    : ""
                }`}
                value={telNumber}
                onChange={(e) => setTelNumber(e.target.value)}
              />
              <label>Telephone Number</label>
            </Col>
            <Col sm={12} lg={6}>
              <input
                type="text"
                className={`mt-20 ${
                  formSubmit && (email === "" || email === null)
                    ? "is-invalid"
                    : ""
                }`}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label>
                Email<span className="danger">*</span>
              </label>
            </Col>
          </>
        ) : null}

        <Col sm={12} lg={6}>
          <input
            type="text"
            className={`mt-20 ${
              formSubmit && (email === "" || email === null) ? "is-invalid" : ""
            }`}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <label>
            Email<span className="danger">*</span>
          </label>
        </Col>
      </Row>

      <Row>
        {userType === "client" ? (
          <>
            <hr />
            <Col lg={12}>
              <input
                type="text"
                className={`mt-20 ${
                  formSubmit && (address === "" || address === null)
                    ? "is-invalid"
                    : ""
                }`}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <label>
                Address<span className="danger">*</span>
              </label>
            </Col>
            <Col sm={12} lg={6}>
              <input
                type="text"
                className={`mt-20 ${
                  formSubmit && (county === "" || county === null)
                    ? "is-invalid"
                    : ""
                }`}
                value={county}
                onChange={(e) => setCounty(e.target.value)}
              />
              <label>
                County<span className="danger">*</span>
              </label>
            </Col>
            <Col sm={12} lg={6}>
              <input
                type="text"
                className={`mt-20 ${
                  formSubmit && (city === "" || city === null)
                    ? "is-invalid"
                    : ""
                }`}
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
              <label>
                City<span className="danger">*</span>
              </label>
            </Col>
            <Col sm={12} lg={6}>
              <Select
                className="mt-20"
                defaultValue={{ label: state, value: state }}
                onChange={(selected) => setState(selected.value)}
                options={AppConstants().us_states}
              />
              <label>
                State<span className="danger">*</span>
              </label>
            </Col>
            <Col sm={12} lg={6}>
              <input
                type="number"
                className={`mt-20 ${
                  formSubmit && (zip === "" || zip === null) ? "is-invalid" : ""
                }`}
                value={zip}
                onChange={(e) => setZip(e.target.value)}
              />
              <label>
                Zip/Postal Number<span className="danger">*</span>
              </label>
            </Col>
          </>
        ) : null}
        <div className="mt-50">
          <center>
            <Button className="rounded" variant="success" onClick={updateData}>
              Update Info
            </Button>
          </center>
        </div>
      </Row>
      <h2 className="primary mt-5">Update Password</h2>
      <hr />
      <Row>
        <Col sm={12} lg={6}>
          <input
            type={showPassword ? "text" : "password"}
            className={`mt-20 ${
              formSubmit && (lastname === "" || lastname === null)
                ? "is-invalid"
                : ""
            }`}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <label>Password</label>

          <div className="form-check mt-20">
            <input
              className="form-check-input"
              type="checkbox"
              checked={showPassword}
              onClick={() => setShowPassword(!showPassword)}
              onChange={() => setShowPassword(!showPassword)}
            />
            <label
              className="form-check-label pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              Show Password
            </label>
          </div>
        </Col>
        <Col sm={12} lg={6}>
          <input
            type={showPassword ? "text" : "password"}
            className={`mt-20 ${
              formSubmit && (lastname === "" || lastname === null)
                ? "is-invalid"
                : ""
            }`}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          <label>Confirm Password</label>

          {/* <div className="form-check mt-20">
            <input
              className="form-check-input"
              type="checkbox"
              checked={showPassword}
              onClick={() => setShowPassword(!showPassword)}
              onChange={() => setShowPassword(!showPassword)}
            />
            <label
              className="form-check-label pointer"
              onClick={() => setShowPassword(!showPassword)}
            >
              Show Password
            </label>
          </div> */}
        </Col>
        <div className="mt-50">
          <center>
            <Button
              className="rounded"
              variant="success"
              onClick={handleUpdatePassword}
              disabled={!password || !confirmPassword}
            >
              Update Password
            </Button>
          </center>
        </div>
      </Row>
    </Container>
  );
}

export default Settings;
