import {
  DatatableWrapper,
  Filter,
  Pagination,
  PaginationOptions,
  TableBody,
  TableHeader,
} from "react-bs-datatable";
import { Col, Row, Table, Button, Badge } from "react-bootstrap";

import tableStyles from "../styles/Table.module.css";

import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const ConsultationTable = ({ data }) => {
  const [consultationTableBody, setConsultationTableBody] = useState([]);

  function mapStatus(status) {
    switch (status) {
      case "forNotification":
        return "For review";
      case "forConsultation":
        return "For consultation";
      case "startConsultation":
        return "Consultation started";
      case "endConsultation":
        return "Consultation ended";
      case "allowUpload":
        return "Allowed to upload";
      case "receivedUpload":
        return "Uploads recieved";
      case "rejected":
        return "For additional information";
      case "underProcess":
        return "Under processing";
      case "waitingPayment":
        return "Waiting for payment";
      case "waitingTaxReturn":
        return "Waiting for tax return";
      case "readyTaxReturn":
        return "Tax return available";
      case "completed":
        return "Completed";
      case "closed":
        return "Closed";
      default:
        break;
    }
  }

  const consultationTableHeader = [
    {
      title: "Accepted",
      prop: "accepted",
      isSortable: true,
      alignment: {
        horizontal: "center",
      },
    },
    {
      title: "Accepted",
      prop: "accepted",
      isSortable: true,
      alignment: {
        horizontal: "center",
      },
      cell: (value) => {
        return (
          <div className="d-flex justify-content-center">
            {value.accepted === 1 ? (
              value.status === "Closed" ? (
                <Badge pill className="" bg="danger">
                  Closed
                </Badge>
              ) : (
                <Badge pill className="" bg="success">
                  Yes
                </Badge>
              )
            ) : (
              <Badge pill className="" bg="warning" text="dark">
                No
              </Badge>
            )}
          </div>
        );
      },
    },
    {
      title: "First Name",
      prop: "firstName",
      isFilterable: true,
      isSortable: true,
    },
    {
      title: "Last Name",
      prop: "lastName",
      isFilterable: true,
      isSortable: true,
    },
    {
      title: "Availability",
      prop: "availability",
      isFilterable: true,
      isSortable: true,
    },
    {
      title: "Submitted",
      prop: "submitted",
      isFilterable: true,
      isSortable: true,
    },
    {
      title: "Status",
      prop: "status",
      isFilterable: true,
      isSortable: true,
    },
    {
      title: "Completed Date",
      prop: "status",
      isFilterable: true,
      isSortable: true,
      cell: (value) => {
        return value.status === "Closed"
          ? `Completed at ${new Date(value.updated_at).toLocaleDateString()}`
          : "Under Process";
      },
    },

    {
      title: "",
      prop: "view",
    },
  ];

  useEffect(() => {
    setConsultationTableBody(
      data.map((item) => {
        return {
          accepted: item.status !== "forNotification" ? 1 : 0,
          firstName: item.user.firstname,
          lastName: item.user.lastname,
          availability:
            item.submission_answers.find(
              ({ question_code }) => question_code === "isAlwaysAvailable"
            ).answer.value === "Yes"
              ? "Always Available"
              : `${
                  item.submission_answers.find(
                    ({ question_code }) => question_code === "availableDay"
                  ).answer.value
                } ${
                  item.submission_answers.find(
                    ({ question_code }) => question_code === "availableTime"
                  ).answer.value
                }`,
          submitted: new Date(item.created_at).toLocaleDateString(),
          status: mapStatus(item.status),
          view: (
            <Link to={`${item.id}`}>
              <Button className=" rounded">View</Button>
            </Link>
          ),
          updated_at: item.updated_at,
        };
      })
    );
  }, [data]);

  /* <div className="d-flex justify-content-center">
      {item.status !== "forNotification" ? (
        <Badge pill className="" bg="success">
          Yes
        </Badge>
      ) : (
        <Badge pill className="" bg="warning" text="dark">
          No
        </Badge>
      )}
    </div> */

  return (
    <div className="consultation-table">
      <DatatableWrapper
        body={consultationTableBody}
        headers={consultationTableHeader}
        paginationOptionsProps={{
          initialState: {
            rowsPerPage: 10,
            options: [5, 10, 15, 20],
          },
        }}
        initialSort={{
          prop: "accepted",
          isAscending: true,
        }}
      >
        <Row className="mb-4">
          <Col
            xs={12}
            lg={4}
            className="d-flex flex-col justify-content-end align-items-end"
          >
            <Filter />
          </Col>
          <Col
            xs={12}
            sm={6}
            lg={4}
            className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
          >
            <PaginationOptions />
          </Col>
          <Col
            xs={12}
            sm={6}
            lg={4}
            className="d-flex flex-col justify-content-end align-items-end"
          >
            <Pagination />
          </Col>
        </Row>
        <Table
          className={`${tableStyles["consultation-table"]} ${tableStyles["table"]}`}
        >
          <TableHeader />
          <TableBody />
        </Table>
      </DatatableWrapper>
    </div>
  );
};
