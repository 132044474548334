import React, { useEffect, useState } from "react";

import { Container, Row, Col, Alert } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import { loginAdmin, loginClient } from "../../service/authService";

import { Button } from "../../components";
import useSession from "../../hooks/useSession";

function Login() {
  const location = useLocation();

  const { url, isAuth, userType } = useSession();

  const [formSubmit, setFormSubmit] = useState(false);
  const [displayMsg, setDisplayMsg] = useState({
    message: "",
    type: "",
  });
  const [isAdmin, setIsAdmin] = useState(null);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  function handleValidation() {
    setFormSubmit(true);
    setDisplayMsg({ type: "success", message: "Logging in..." });

    if (email === "") {
      return setDisplayMsg({
        type: "error",
        message: "Please enter your registered email.",
      });
    }
    if (password === "") {
      return setDisplayMsg({
        type: "error",
        message: "Please enter your password.",
      });
    }

    handleSubmit();
  }

  function handleSubmit() {
    if (!isAdmin) {
      loginClient({
        email: email,
        password: password,
      })
        .then((res) => {
          window.location.replace(url.baseUrl + "consultation");
        })
        .catch((e) => {
          console.log(e);
          setDisplayMsg({
            type: "error",
            message: "Invalid Credentials",
          });
        });
    } else {
      loginAdmin({
        email: email,
        password: password,
      })
        .then((res) => {
          window.location.replace(url.baseUrl + "admin/dashboard");
        })
        .catch((e) => {
          console.log(e);
          setDisplayMsg({
            type: "error",
            message: "Invalid Credentials",
          });
        });
    }
  }

  useEffect(() => {
    setIsAdmin(location.pathname.split("/").includes("admin"));
    if (isAuth) {
      if (userType === "client") {
        window.location.replace(url.baseUrl + "consultation");
      } else {
        window.location.replace(url.baseUrl + "admin/dashboard");
      }
    }
    //eslint-disable-next-line
  }, [isAuth]);

  return (
    <Container className="container-space">
      <center>
        <h1 className="primary">Login</h1>
      </center>

      <Container className="mt-30">
        <Row>
          <center>
            <Col className="container-center" md={12} lg={6}>
              {displayMsg.message ? (
                <Alert
                  variant={displayMsg.type === "error" ? "danger" : "success"}
                >
                  {displayMsg.message}
                </Alert>
              ) : null}
              <input
                type="text"
                className={`${
                  formSubmit && (email === "" || email === null)
                    ? "is-invalid"
                    : ""
                }`}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleValidation() : null
                }
              />
              <input
                type={showPassword ? "text" : "password"}
                className={`mt-20 ${
                  formSubmit && (password === "" || password === null)
                    ? "is-invalid"
                    : ""
                }`}
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={(e) =>
                  e.key === "Enter" ? handleValidation() : null
                }
              />

              <div className="d-flex align-items-center justify-content-between mt-20 ">
                <div className="form-check  text-left">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={showPassword}
                    onClick={() => setShowPassword(!showPassword)}
                    onChange={() => setShowPassword(!showPassword)}
                  />
                  <label
                    className="form-check-label pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    Show Password
                  </label>
                </div>
                <Link to="/auth/forgot-password" className="d-block">
                  Forgot Password
                </Link>
              </div>
              <div className="mt-4 d-flex gap-3 justify-content-center">
                <Button
                  buttonStyle="btn--primary btn--large"
                  onClick={handleValidation}
                >
                  Login
                </Button>
              </div>
            </Col>
          </center>
        </Row>
      </Container>
    </Container>
  );
}

export default Login;
